import React, { useEffect, useState } from 'react';
import { SocietyData, SocietyDataForm } from '../../../../app/models/Society';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import useRut from '../../../../hooks/useRut';
import { User } from '../../../../app/models/User';
import { Client } from '../../../../app/models/Client';
import { Provider } from '../../../../app/models/Provider';
import SelectTwo, { SelectTwoMapperOptions } from '../../../../components/SelectTwo';

interface GeneralSocietyProps {
    societyForm: SocietyDataForm;
    setSocietyForm: (society: SocietyDataForm) => void;
    clients: Client[];
    providers: Provider[];
    errorFields?: any;
    setErrorFields?: (errors: any) => void;
}

const SocietyForm = ({
    societyForm,
    setSocietyForm,
    clients,
    providers,
    errorFields,
    setErrorFields
}: GeneralSocietyProps) => {

    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();
    const { formatRut } = useRut();

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    const [image, setImage] = useState(
        societyForm.logo
            ? societyForm.logo
            : 'https://static.vecteezy.com/system/resources/previews/009/292/244/original/default-avatar-icon-of-social-media-user-vector.jpg'
    );

    const handleChange = (
        event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
    ) => {
        const { name, value, type } = event.target;
        if (type === 'checkbox') {
            event.target = event.target as HTMLInputElement;
            setSocietyForm({ ...societyForm, [name]: event.target.checked });
        } else {
            if (name == 'rut') {
                setSocietyForm({ ...societyForm, [name]: formatRut(value) });
            } else {
                setSocietyForm({ ...societyForm, [name]: value });
            }
        }
    };

    const dataOnChangeImages = (e: any) => {
        setImage(URL.createObjectURL(e.target.files[0]));
        setSocietyForm({
            ...societyForm,
            [e.target.name]: e.target.files[0]
        });
    };

    const changeMultipleSelectTwo = (name: string, optionSelect: any) => {
        
        let obj = {};

        if (name === 'clients'){
            obj = { clients: optionSelect };
        }
        else if (name === 'providers'){
            obj = { providers: optionSelect };
        }
        

        setSocietyForm({
            ...societyForm,
            ...obj
        });
       
    };

    

    return (
        <>
            <div className="col-md-12 text-center d-flex">
                <div className="mx-auto mb-3">
                    <label htmlFor="logo">
                        <img
                            src={
                                typeof image === 'string' && image !== ''
                                    ? image
                                    : window.URL.createObjectURL(image as Blob)
                            }
                            className="rounded-circle pointer bg-white"
                            style={{
                                width: '150px',
                                height: '150px',
                                objectFit: 'cover'
                            }}
                        />
                    </label>
                </div>
                <input
                    type="file"
                    className="custom-file-input"
                    style={{ height: '0px', width: '0px' }}
                    id="logo"
                    name="logo"
                    onChange={dataOnChangeImages}
                />
            </div>
            <div className="mb-2 row">
                <div className="col-md-6">
                    <label className="col-form-label">RUT</label>
                    <input
                        name="rut"
                        id="rut"
                        type="text"
                        className="form-control"
                        value={societyForm.rut}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('rut')}
                        placeholder="Ingrese el RUT"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('rut')}
                    </div>
                </div>
                <div className="col-md-6">
                    <label className="col-form-label">Nombre</label>
                    <input
                        name="name"
                        id="name"
                        type="text"
                        className={`form-control ${fieldHasError('name')}`}
                        value={societyForm.name}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('name')}
                        placeholder="Ingrese el nombre"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('name')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label"> Dirección </label>
                    <input
                        name="address"
                        id="address"
                        type="text"
                        className={`form-control ${fieldHasError('address')}`}
                        value={societyForm.address}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('address')}
                        placeholder="Ingrese la dirección"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('address')}
                    </div>
                </div>
            </div>
            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label"> Email</label>
                    <input
                        name="email"
                        id="email"
                        type="email"
                        className={`form-control ${fieldHasError('email')}`}
                        value={societyForm.email}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('email')}
                        placeholder="Ingrese la dirección"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('email')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row needs-validation">
                <div className="col-md-12 ">
                    <label className=" col-form-label">Teléfono</label>
                    <div className="row">
                        <div className="col-auto">
                            <select
                                className="form-control form-control-custom pl-2"
                                id="phone_code"
                                name="phone_code"
                                onChange={handleChange}
                                value={societyForm.phone_code}
                                onFocus={() => onFocusRemove('phone_code')}
                            >
                                <option defaultValue={'+56'} value="+56">
                                    +56
                                </option>
                            </select>
                        </div>

                        <div className="col">
                            <input
                                name="phone"
                                id="phone"
                                type="number"
                                className="form-control"
                                value={societyForm.phone}
                                onChange={handleChange}
                                onFocus={() => onFocusRemove('phone')}
                                placeholder="Teléfono"
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('phone')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Clientes</label>

                    <SelectTwo
                        name="clients"
                        id="clients"
                        inputValue={societyForm.clients}
                        options={SelectTwoMapperOptions(clients)}
                        hasError={fieldHasError('clients') !== ''}
                        onChange={(optionSelect) => changeMultipleSelectTwo('clients', optionSelect)}
                        isMulti={true}
                        placeholder={'Seleccione cliente(s)'}
                        onFocus={() => onFocusRemove('clients')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('clients')}
                    </div>
                </div>
            </div>
            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Proveedores</label>

                    <SelectTwo
                        name="providers"
                        id="providers"
                        inputValue={societyForm.providers}
                        options={SelectTwoMapperOptions(providers)}
                        hasError={fieldHasError('providers') !== ''}
                        onChange={(optionSelect) => changeMultipleSelectTwo('providers', optionSelect)}
                        isMulti={true}
                        placeholder={'Seleccione proveedor(es)'}
                        onFocus={() => onFocusRemove('providers')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('providers')}
                    </div>
                </div>
            </div>
        </>
    );
};

export default SocietyForm;
