import React, { useEffect } from 'react';
import { Client } from '../../../app/models/Client';
import useHandleErrorFields from '../../../hooks/useHandleErrorFields';
import useRut from '../../../hooks/useRut';

type Props = {
    client: Client;
    setClient: (client: Client) => void;
    errorFields?: any;
};

const ClientForm = ({ client, setClient, errorFields }: Props) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();
    const { formatRut } = useRut();

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
    ) => {
        const { name, value } = e.target;
        if (name == 'rut' || name == 'rut_contact') {
            setClient({ ...client, [name]: formatRut(value) });
        } else {
            setClient({ ...client, [name]: value });
        }
    };

    return (
        <>
            <div className="mb-2 row needs-validation">
                <label className="col-md-3 col-form-label">Razón Social</label>
                <div className="col-md-9">
                    <input
                        name="name"
                        id="name"
                        type="text"
                        className={`form-control ${fieldHasError('name')}`}
                        value={client.name}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('name')}
                        placeholder="Ingrese la razón social"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('name')}
                    </div>
                </div>
            </div>

            {/*         RUT cliente es lo que antes era Rut Contacto */}

            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Rut cliente</label>
                <div className="col-md-9">
                    <input
                        name="rut"
                        id="rut"
                        type="text"
                        className="form-control"
                        value={client.rut}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('rut')}
                        placeholder="Ingrese el RUT Cliente"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('rut')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Email de contacto</label>
                <div className="col-md-9">
                    <input
                        name="email"
                        id="email"
                        type="email"
                        className={`form-control ${fieldHasError('email')}`}
                        value={client.email}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('email')}
                        placeholder="Ingrese el email"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('email')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Teléfono del contacto</label>
                <div className="col-md-2 col-3">
                    <select
                        className="form-control form-control-custom pl-2"
                        id="phone_code"
                        name="phone_code"
                        onChange={handleChange}
                        value={client.phone_code}
                        onFocus={() => onFocusRemove('phone_code')}
                    >
                        <option defaultValue={'+56'} value="+56">
                            +56
                        </option>
                    </select>
                </div>
                <div className="col-md-7 col-9 ">
                    <input
                        name="phone"
                        id="phone"
                        type="number"
                        className="form-control"
                        value={client.phone}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('phone')}
                        placeholder="Ingrese el teléfono"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('phone')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Dirección del cliente</label>
                <div className="col-md-9">
                    <input
                        name="address"
                        id="address"
                        type="text"
                        className="form-control"
                        value={client.address}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('address')}
                        placeholder="Ingrese la dirección"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('address')}
                    </div>
                </div>
            </div>
        </>
    );
};

export default ClientForm;
