import React from 'react';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-6">2024 © ERP - El Origen.</div>
                    <div className="col-sm-6">
                        {/*<div className="text-sm-end d-none d-sm-block">Sistema TICSA</div>*/}
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
