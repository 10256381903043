import React from 'react';
import useSweetAlert from '../../../hooks/useSweetAlert';
import { TransportationOrderStatus } from '../../../app/models/TransportationOrderStatus';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import useTransportationOrderItemService from '../../../app/services/hooks/useTransportationOrderItemService';
import { TransportationOrderDataForm } from '../../../app/models/TransportationOrder';

interface Props {
    transportationOrderItemId: number;
    statuses: TransportationOrderStatus[];
    selectedStatus: TransportationOrderStatus;
    reloadModule?: () => void;
    callbackSuccess?: () => void;
}

const TransportationOrderItemChangeStatus = ({
    transportationOrderItemId,
    statuses,
    selectedStatus,
    callbackSuccess,
    reloadModule
}: Props) => {
    const [selected, setSelected] = React.useState<TransportationOrderStatus>(selectedStatus);

    const {
        changeStatusTransportationOrderItem,
        HasAllFinishedTransportationOrderItems,
        fetchingChangeStatusTransportationOrderItem,
        fetchingHasAllFinishedTransportationOrderItems
    } = useTransportationOrderItemService();

    const hasFinished = (id: number, value: string) => {
        useSweetAlert().spinnerAlert('cargando...');
        if (value == selected.id) return null;

        const _status = statuses.find((status: TransportationOrderStatus) => status.id == value);

        HasAllFinishedTransportationOrderItems(id, value, {
            onSuccess: (response: ServiceResponse) => {
                useSweetAlert().closeSpinnerAlert();
                changeStatus(_status, response.data.message);
            },
            onError: (response: ServiceResponse) => {
                useSweetAlert().closeSpinnerAlert();
                setSelected(selected);
                useSweetAlert().errorAlert({
                    title: 'Error',
                    text: response.message
                });
            }
        });
    };

    const changeStatus = (_status: any, message: string) => {
        const status_value = _status ? _status.name : '';
        const _text = `Está a punto de cambiar el estado de la posición de pedido #${transportationOrderItemId} a ${status_value}.${
            message ?? ''
        }`;

        useSweetAlert().requestConfirmation({
            title: '¿Está seguro?',
            text: _text,
            confirmButtonText: 'Si, cambiar estado',
            cancelButtonText: 'Cancelar',
            onConfirm: () => {
                useSweetAlert().spinnerAlert('Cambiando estado...');
                changeStatusTransportationOrderItem(transportationOrderItemId, _status.id, {
                    onSuccess: (response: ServiceResponse) => {
                        useSweetAlert().closeSpinnerAlert();

                        setSelected(_status ?? selected);

                        if (reloadModule) reloadModule();
                        if (callbackSuccess) {
                            callbackSuccess();
                        }

                        toast.success(response.message);
                    },
                    onError: (response: ServiceResponse) => {
                        useSweetAlert().closeSpinnerAlert();
                        setSelected(selected);
                        toast.error(response.message);
                    }
                });
            },
            onCancel: () => {
                useSweetAlert().infoAlert({
                    title: 'Cancelado',
                    text: 'El estado del pedido no se ha cambiado.'
                });
                setSelected(selectedStatus);
            }
        });
    };

    if (!selectedStatus) return null;

    return (
        <select
            className="form-control"
            value={selected.id}
            onChange={(e) => hasFinished(transportationOrderItemId, String(e.target.value))}
        >
            {statuses
                ? statuses.map((status) => {
                      return (
                          <option key={status.id} value={status.id}>
                              {status.name}
                          </option>
                      );
                  })
                : null}
        </select>
    );
};

export default TransportationOrderItemChangeStatus;
