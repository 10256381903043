import React from 'react';
import Select from 'react-select';
import { select2Config, StyleCondition } from '../config';

interface SelectTwoProps {
    name?: string;
    id?: string;
    onFocus?: (value: any) => void;
    inputValue?: any;
    options: SelectTwoOption[];
    onChange?: (value: any) => void;
    placeholder?: string;
    hasError?: boolean;
    isMulti?: boolean;
    disabled?: boolean;
    isClearable?: boolean;
}

interface SelectTwoOption {
    value: string | number | null;
    label: string;
}

export const SelectTwoMapperOptions = (options: any[], valueName = 'id', labelNames = ['name'], extra_fields?: any, condition?: StyleCondition) => {
    return options.map((option: any) => {
        const labels: string[] = [];
        labelNames.map((labelName: string) => {
            labels.push(option[labelName]);
        });

        const result: any = {
            value: option[valueName],
            label: labels.join(' - ')
        };

        if(extra_fields){

            extra_fields.forEach((field:any) => {
                if (Object.prototype.hasOwnProperty.call(option, field)) {
                    result[field] = option[field];
                }
            });
    
        }

        if (condition) {

            Object.assign(result, { ['condition']: condition });
        }

        return result
    });
};

const selectedOption = (options: SelectTwoOption[], value: string | number | null) => {
    return options.find((option: SelectTwoOption) => option.value == value) || null;
};

const SelectTwo = ({
    name,
    id,
    options,
    onChange,
    placeholder = 'Seleccione una opción',
    inputValue = '',
    hasError = false,
    onFocus,
    isMulti = false,
    disabled = false,
    isClearable = false
}: SelectTwoProps) => {
    return (
        <Select
            id={id}
            name={name}
            options={options}
            placeholder={placeholder}
            styles={select2Config(hasError)}
            isMulti={isMulti}
            onChange={onChange}
            onFocus={onFocus}
            isDisabled={disabled}
            isClearable={isClearable}
            value={Array.isArray(inputValue) ? inputValue : selectedOption(options, inputValue)}
        />
    );
};

export default SelectTwo;
