import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Breadcrumbs, { BreadcrumbsItem } from '../../../template/MainTheme/components/Breadcrumbs';
import useTransportationOrderService from '../../../app/services/hooks/useTransportationOrderService';
import {
    defaultTransportationOrderDataForm,
    defaultTransportationOrderRateDataForm,
    TransportationOrderDataForm,
    TransportationOrderRatesDataForm
} from '../../../app/models/TransportationOrder';
import DefaultCard from '../../../components/default/DefaultCard';
import LazyLoading from '../../../components/LazyLoading';
import DefaultCreatedModifiedUserForm from '../../../components/default/DefaultCreatedModifiedUserForm';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import TransportationOrderShowForm from '../components/forms/TransportationOrderShowForm';
import TransportationOrderItemsContainer from '../components/TransportationOrderItemsContainer';
import { TransportationQuoteDetail } from '../../../app/models/TransportationQuoteDetail';
import { Currency } from '../../../app/models/Currency';
import { Unit } from '../../../app/models/Unit';
import TransportationOrderRatesForm from '../components/forms/TransportationOrderRatesForm';
import TransportationOrderRatesShow from '../components/TransportationOrderRatesShow';

type RouterParams = {
    id: string;
};

const TransportationOrderShow = () => {
    const { id } = useParams<RouterParams>();

    const [errorFields, setErrorFields] = useState<any>();
    const { showTransportationOrder, fetchingShowTransportationOrder } =
        useTransportationOrderService();
    const [quotes, setQuotes] = useState<TransportationQuoteDetail[]>([]);
    const [currencies, setCurrencies] = useState<Currency[]>([]);
    const [quantityUnits, setQuantityUnits] = useState<Unit[]>([]);

    const [transportationOrderForm, setTransportationOrderForm] =
        useState<TransportationOrderDataForm>(defaultTransportationOrderDataForm);

    const [transportationOrderRatesForm, setTransportationOrderRatesForm] =
        useState<TransportationOrderRatesDataForm>(defaultTransportationOrderRateDataForm);

    useEffect(() => {
        show(parseInt(id));
    }, [id]);

    const show = (id: number) => {
        showTransportationOrder(id, {
            onSuccess: (response: ServiceResponse) => {
                setTransportationOrderForm(response.data.transportation_order);
                setTransportationOrderRatesForm(response.data.transportation_order);
                setQuotes(response.data.quotes);
            }
        });
    };

    const breadcrumbs: BreadcrumbsItem[] = [
        {
            name: 'Pedidos de Transporte',
            url: '/transportation-orders',
            isActive: false
        },
        {
            name: 'Ver pedido de transporte #' + id,
            url: '/transportation-orders/' + id + '/show',
            isActive: true
        }
    ];

    return (
        <>
            <Breadcrumbs pageSection={`Visualizar Pedido #${id}`} breadcrumbs={breadcrumbs} />

            <div className="row">
                <div className="col-md-6">
                    <DefaultCard>
                        <div className="h3 mt-0 mb-2 card-title">Datos Generales</div>
                        {!fetchingShowTransportationOrder ? (
                            <>
                                <TransportationOrderShowForm
                                    transportationOrderDataForm={transportationOrderForm}
                                    quotes={quotes}
                                />
                                {/*<TransportationOrderRatesShow*/}
                                {/*    transportationOrderRatesForm={transportationOrderRatesForm}*/}
                                {/*/>*/}
                            </>
                        ) : (
                            <LazyLoading />
                        )}
                    </DefaultCard>
                </div>
                <div className="col-md-6">
                    <div className="card">
                        <div className="card-body">
                            <div className="h3 mt-0 mb-2 card-title">Usuarios</div>
                            {!fetchingShowTransportationOrder ? (
                                <DefaultCreatedModifiedUserForm
                                    createdBy={transportationOrderForm.created_user}
                                    createdAt={transportationOrderForm.created_at}
                                    updatedBy={transportationOrderForm.last_modified_user}
                                    updatedAt={transportationOrderForm.updated_at}
                                    status={transportationOrderForm.status}
                                    // status={
                                    //     transportationOrderForm.status_selected
                                    //         ? transportationOrderForm.status_selected.name.toString()
                                    //         : ''
                                    // }
                                />
                            ) : (
                                <LazyLoading />
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <TransportationOrderItemsContainer
                transportationOrderId={parseInt(id)}
                canBeEdited={false}
                currencyId={transportationOrderForm.currency_id}
                isShow={true}
                transportationOrderForm={transportationOrderForm}
                setTransportationOrderForm={setTransportationOrderForm}
            />
        </>
    );
};

export default TransportationOrderShow;
