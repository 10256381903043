import React from 'react';

interface BreadcrumbsProps {
    pageSection: string;
    breadcrumbs: BreadcrumbsItem[];
}

export interface BreadcrumbsItem {
    name: string;
    url: string;
    isActive: boolean;
}

const Breadcrumbs = ({ pageSection, breadcrumbs }: BreadcrumbsProps) => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-0 font-size-18">{pageSection}</h4>
                        <div className="page-title-right">
                            <nav className="" aria-label="breadcrumb">
                                <ol className="breadcrumb m-0">
                                    {
                                        <li
                                            className={
                                                breadcrumbs.length == 1
                                                    ? 'breadcrumb-item active'
                                                    : 'breadcrumb-item'
                                            }
                                        >
                                            <a href="/">Dashboard</a>
                                        </li>
                                    }
                                    {breadcrumbs.map((item, index) => {
                                        return (
                                            <li
                                                key={`bc-${index}`}
                                                className={
                                                    item.isActive
                                                        ? 'breadcrumb-item active'
                                                        : 'breadcrumb-item'
                                                }
                                            >
                                                <a href={item.url}>{item.name}</a>
                                            </li>
                                        );
                                    })}
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Breadcrumbs;
