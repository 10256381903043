import useFetch from '../../../hooks/useFetch';
import { useState } from 'react';

import { ServiceEvents } from '../shared/interfaces';
import { EndPoints } from '../EndPoints';
import {
    CheckingAccount,
    CheckingAccountDataForm,
    CheckingAccountFilters
} from '../../models/CheckingAccount';

const useCheckingAccountService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [fetchingGetCheckingAccounts, setFetchingGetCheckingAccounts] = useState(false);
    const [fetchingShowCheckingAccount, setFetchingShowCheckingAccount] = useState(false);
    const [fetchingCreateCheckingAccount, setFetchingCreateCheckingAccount] = useState(false);
    const [fetchingStoreCheckingAccount, setFetchingStoreCheckingAccount] = useState(false);
    const [fetchingEditCheckingAccount, setFetchingEditCheckingAccount] = useState(false);
    const [fetchingUpdateCheckingAccount, setFetchingUpdateCheckingAccount] = useState(false);
    const [fetchingDeleteCheckingAccount, setFetchingDeleteCheckingAccount] = useState(false);
    const [fetchingActiveCheckingAccount, setFetchingActiveCheckingAccount] = useState(false);

    const getCheckingAccounts = (
        CheckingAccountFilter: CheckingAccountFilters,
        events: ServiceEvents = {}
    ) => {
        const queryString = Object.entries(CheckingAccountFilter)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.CHECKING_ACCOUNTS.GET_CHECKING_ACCOUNTS}?${queryString}`;
        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetCheckingAccounts
        });
    };

    const showCheckingAccount = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.CHECKING_ACCOUNTS.SHOW_CHECKING_ACCOUNT.replace(':id', id.toString()),
            setFetching: setFetchingShowCheckingAccount
        });
    };

    const createCheckingAccount = (events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.CHECKING_ACCOUNTS.CREATE_CHECKING_ACCOUNT,
            setFetching: setFetchingCreateCheckingAccount
        });
    };

    const storeCheckingAccount = (
        checkingAccount: CheckingAccountDataForm,
        events: ServiceEvents = {}
    ) => {
        doPost({
            ...events,
            url: EndPoints.CHECKING_ACCOUNTS.STORE_CHECKING_ACCOUNT,
            body: checkingAccount,
            setFetching: setFetchingStoreCheckingAccount
        });
    };

    const editCheckingAccount = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.CHECKING_ACCOUNTS.EDIT_CHECKING_ACCOUNT.replace(':id', id.toString()),
            setFetching: setFetchingEditCheckingAccount
        });
    };

    const updateCheckingAccount = (
        id: number,
        checkingAccount: CheckingAccountDataForm,
        events: ServiceEvents = {}
    ) => {
        doPut({
            ...events,
            url: EndPoints.CHECKING_ACCOUNTS.UPDATE_CHECKING_ACCOUNT.replace(':id', id.toString()),
            body: checkingAccount,
            setFetching: setFetchingUpdateCheckingAccount
        });
    };

    const deleteCheckingAccount = (id: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.CHECKING_ACCOUNTS.DELETE_CHECKING_ACCOUNT.replace(':id', id.toString()),
            setFetching: setFetchingDeleteCheckingAccount
        });
    };

    const activeCheckingAccount = (id: number, active: boolean, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            body: { active: active },
            url: EndPoints.CHECKING_ACCOUNTS.ACTIVE_CHECKING_ACCOUNT.replace(':id', id.toString()),
            setFetching: setFetchingActiveCheckingAccount
        });
    };

    return {
        fetchingGetCheckingAccounts,
        fetchingCreateCheckingAccount,
        fetchingShowCheckingAccount,
        fetchingStoreCheckingAccount,
        fetchingEditCheckingAccount,
        fetchingUpdateCheckingAccount,
        fetchingDeleteCheckingAccount,
        getCheckingAccounts,
        createCheckingAccount,
        activeCheckingAccount,
        showCheckingAccount,
        storeCheckingAccount,
        editCheckingAccount,
        updateCheckingAccount,
        deleteCheckingAccount
    };
};

export default useCheckingAccountService;
