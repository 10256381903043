import React from 'react';
import PlaceForm from './PlaceForm';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { Place } from '../../../app/models/Place';

interface Props {
    fetching?: boolean;
    action: () => void;
    cancel: () => void;
    place: Place;
    setPlace: (Place: Place) => void;
    errorFields?: any;
}

const PlaceFormContainer = ({
    fetching,
    action,
    cancel,
    place,
    setPlace,
    errorFields = null
}: Props) => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <PlaceForm place={place} setPlace={setPlace} errorFields={errorFields} />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetching} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={action} locked={fetching} />
                </div>
            </div>
        </>
    );
};

export default PlaceFormContainer;
