import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import useSocietyService from '../../../app/services/hooks/useSocietyService';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { SocietyData, SocietyDataForm, defaultSocietyForm } from '../../../app/models/Society';
import SocietyForm from './forms/SocietyForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import { AppContext } from '../../../contexts/AppContext';
import LazyLoading from '../../../components/LazyLoading';
import SocietyFormContainer from './SocietyFormContainer';
import { Client } from '../../../app/models/Client';
import { Provider } from '../../../app/models/Provider';


interface Props {
    SocietyId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const SocietyEdit = ({
    SocietyId,
    onSaved,
    onCancel,
    onError
}: Props) => {
    const history = useHistory();
    const { showLoading, hideLoading } = useContext(AppContext);
    const { editSociety, updateSociety,fetchingEditSociety,fetchingUpdateSociety } = useSocietyService();
    const [society, setSociety] = useState<SocietyDataForm>(defaultSocietyForm);
    const [errorFields, setErrorFields] = useState<any>();
    const [clients, setClients] = useState<Client[]>([]);
    const [providers, setProviders] = useState<Provider[]>([]);

    useEffect(() => {
        edit();
    }, []);

    const edit = () => {
        if (showLoading) showLoading('Cargando sociedad...');
        editSociety(SocietyId, {
            onSuccess: (response: ServiceResponse) => {

                setClients(response.data.clients);
                setProviders(response.data.providers);

                const _society = response.data.society;
                _society.clients = response.data.society.clients.map(
                    (client: any) => {
                        return {
                            value: client.id,
                            label: client.name,
                        };
                    }
                );
                _society.providers = response.data.society.providers.map(
                    (provider: any) => {
                        return {
                            value: provider.id,
                            label: provider.name,
                        };
                    }
                );

                setSociety(_society);
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    };

    const update = () => {
        if (showLoading) showLoading('Actualizando sociedad...');
        updateSociety(society.id!, society, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.success(response.message);
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingEditSociety ? (
        <LazyLoading height="300"/>
    ) : (
        <SocietyFormContainer
            fetching={fetchingUpdateSociety}
            action={update}
            cancel={cancel}
            societyForm={society}
            setSocietyForm={setSociety}
            clients={clients}
            providers={providers}
            errorFields={errorFields}
        />

    );

};

export default SocietyEdit;
