import { useHistory } from 'react-router-dom';
import Breadcrumbs, { BreadcrumbsItem } from '../../template/MainTheme/components/Breadcrumbs';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import { SocietyData, SocietyDataForm, SocietyFilters } from '../../app/models/Society';

import DefaultCard from '../../components/default/DefaultCard';
import ButtonCreate from '../../components/buttons/ButtonCreate';

import { ServiceResponse } from '../../app/services/shared/interfaces';
import useSocietyService from '../../app/services/hooks/useSocietyService';
import DefaultModal from '../../components/default/DefaultModal';
import SocietyCreate from './components/SocietyCreate';
import SocietyEdit from './components/SocietyEdit';
import { AppContext } from '../../contexts/AppContext';
import useReactConfirmAlert from '../../hooks/useReactConfirmAlert';
import SocietyDataTable from './components/tables/SocietyDataTable';
import { toast } from 'react-toastify';
import useNavigationPage from '../../hooks/useNavigationPage';

const breadcrumbs: BreadcrumbsItem[] = [
    {
        name: 'Sociedad',
        url: '/societies',
        isActive: true
    }
];

const Society = () => {
    const history = useHistory();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { fetchingGetSocieties, getSocieties, deleteSociety, activeSociety } =
        useSocietyService();

    const { auth } = useContext(AuthContext);
    const { navigationPage } = useNavigationPage();

    const [societyIdEdit, setSocietyIdEdit] = useState<number>(-1);
    const [showingEdit, setShowingEdit] = useState(false);
    const [societies, setSocieties] = useState<SocietyData[]>([]);
    const [totalRows, setTotalRows] = useState(0);

    const [showingCreate, setShowingCreate] = useState(false);

    const [filter, setFilter] = useState<SocietyFilters>({
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'desc',
        rut: ''
    });

    useEffect(() => {
        reloadTable();
    }, [filter]);

    const reloadTable = () => {
        getSocieties(filter, {
            onSuccess: (response: ServiceResponse) => {
                setSocieties(response.data.societies);
                setTotalRows(response.data.total_rows);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message);
                if (response.data.to_dashboard) {
                    navigationPage('/');
                }
            }
        });
    };

    const showCreate = () => {
        setShowingCreate(true);
    };

    const hideCreate = () => {
        setShowingCreate(false);
    };

    const showEdit = (SocietyId: number) => {
        // navigationPage('/societies/' + SocietyId + '/edit')
        setShowingEdit(true);
        setSocietyIdEdit(SocietyId);
    };

    const hideEdit = () => {
        setShowingEdit(false);
        setSocietyIdEdit(-1);
    };

    const onError = () => {
        setShowingEdit(false);
        setSocietyIdEdit(-1);
    };

    const destroy = (societyId: number) => {
        const _text = 'Está a punto de eliminar el sociedad #' + societyId;

        useReactConfirmAlert().requestConfirmation({
            title: '¿Estás seguro?',
            message: _text,
            buttons: {
                confirmButton: {
                    label: 'Si, confirmar',
                    onClick: () => {
                        if (showLoading) showLoading('loading', 'Eliminando sociedad...');
                        deleteSociety(societyId, {
                            onSuccess: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();
                                // useSweetAlert().successAlert({
                                //     title: 'Éxito',
                                //     text: response.message

                                // });
                                useReactConfirmAlert().successAlert({
                                    title: 'Éxito',
                                    message: response.message
                                });
                                // if(changeAnimation)changeAnimation('success', response.message, true);
                                reloadTable();
                            },
                            onError: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().errorAlert({
                                    title: 'Error',
                                    message: response.message
                                });
                            }
                        });
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: 'El sociedad no se ha eliminado.'
                            });
                        }, 0);
                    }
                }
            }
        });
    };

    const active = (carrierId: number, active: boolean) => {
        activeSociety(carrierId, active, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });

                reloadTable();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            }
        });
    };

    return (
        <>
            <Breadcrumbs pageSection="Sociedades" breadcrumbs={breadcrumbs} />
            <DefaultCard>
                <div className="row">
                    <div className="col-12">
                        <div className="row">
                            <div className="col">
                                <ButtonCreate callbackCreate={showCreate} title="Nueva Sociedad" />
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <SocietyDataTable
                            societies={societies}
                            totalRows={totalRows}
                            loading={fetchingGetSocieties}
                            destroy={destroy}
                            edit={showEdit}
                            active={active}
                            filter={filter}
                            setFilter={setFilter}
                            callbackSuccess={reloadTable}
                        />
                    </div>
                </div>
            </DefaultCard>
            {showingCreate ? (
                <DefaultModal
                    show={showingCreate}
                    handleClose={hideCreate}
                    title="Crear sociedad"
                    backdrop={true}
                    showFooter={false}
                >
                    <SocietyCreate
                        onSaved={() => {
                            reloadTable();
                            hideCreate();
                        }}
                        onCancel={hideCreate}
                        onError={hideCreate}
                    />
                </DefaultModal>
            ) : null}
            {showingEdit ? (
                <DefaultModal
                    show={showingEdit}
                    handleClose={hideEdit}
                    title="Editar sociedad"
                    backdrop={true}
                    showFooter={false}
                >
                    <SocietyEdit
                        SocietyId={societyIdEdit}
                        onSaved={() => {
                            reloadTable();
                            hideEdit();
                        }}
                        onCancel={hideCreate}
                        onError={hideCreate}
                    />
                </DefaultModal>
            ) : null}
        </>
    );
};

export default Society;
