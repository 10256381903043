import React, { useContext } from 'react';
import { AuthContext } from '../../../../contexts/AuthContext';
import { InternalSettlementFilters } from '../../../../app/models/InternalSettlement';

type Props = {
    filter: InternalSettlementFilters;
    setFilter: (filter: InternalSettlementFilters) => void;
};

const InternalSettlementFilter = ({ filter, setFilter }: Props) => {
    const { auth } = useContext(AuthContext);

    const handlerInputFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        });
    };

    const handlerSelectFilter = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        });
    };

    return (
        <>
            <div className="row justify-content-start">
                <div className="col-lg-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="issue_date_from">
                            Fecha de emisión (desde)
                        </label>
                        <input
                            title="Fecha de emisión"
                            type="date"
                            id="issue_date_from"
                            name="issue_date_from"
                            className="form-control form-control-sm"
                            value={filter.issue_date_from}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
                <div className="col-lg-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="issue_date_until">
                            Fecha de emisión (hasta)
                        </label>
                        <input
                            title="Fecha de emisión"
                            type="date"
                            id="issue_date_until"
                            name="issue_date_until"
                            className="form-control form-control-sm"
                            value={filter.issue_date_until}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>

                <div className="col-lg-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="movement_type">
                            Tipo de Movimiento
                        </label>
                        <select
                            id="movement_type"
                            name="movement_type"
                            className="form-control form-select-sm"
                            value={filter.movement_type ?? ''}
                            onChange={(e) => handlerSelectFilter(e)}
                        >
                            <option key={''} value="">
                                Todos
                            </option>
                            <option key={'CREDIT'} value="CREDIT">
                                Cargo
                            </option>
                            <option key={'DEBIT'} value="DEBIT">
                                Abono
                            </option>
                        </select>
                    </div>
                </div>
              
            </div>
        </>
    );
};

export default InternalSettlementFilter;
