import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import usePaymentStatusService from '../../../../app/services/hooks/usePaymentStatusService';
import { AuthContext } from '../../../../contexts/AuthContext';
import { PaymentStatusDataForm, PaymentStatusesStatus} from '../../../../app/models/PaymentStatus';
import { RouterParams } from '../../../../app/shared/interfaces';
import Breadcrumbs, { BreadcrumbsItem } from '../../../../template/MainTheme/components/Breadcrumbs';
import useReactConfirmAlert from '../../../../hooks/useReactConfirmAlert';
import { ServiceResponse } from '../../../../app/services/shared/interfaces';
import PaymentChangeStatus from '../PaymentChangeStatus';
import ButtonSaveForm from '../../../../components/buttons/ButtonSaveForm';
import DefaultCard from '../../../../components/default/DefaultCard';
import LazyLoading from '../../../../components/LazyLoading';
import { AppContext } from '../../../../contexts/AppContext';
import useNavigationPage from '../../../../hooks/useNavigationPage';
import PaymentStatusForm from '../PaymentStatusForm';
import { PaymentStatusDetail } from '../../../../app/models/PaymentStatusDetail';
import usePaymentStatusDetailService from '../../../../app/services/hooks/usePaymentStatusDetailService';
import PaymentStatusDetailContainer from '../PaymentStatusDetailContainer';


const PaymentStatusEdit = () => {
    const { id } = useParams<RouterParams>();
    const { navigationPage } = useNavigationPage();
    const { showLoading, hideLoading } = useContext(AppContext);
    const { auth } = useContext(AuthContext);

    const {
        fetchingChangeStatusPaymentStatus,
        changeStatusPaymentStatus,
        editPaymentStatus,
        canChangePaymentStatuses,
        fetchingEditPaymentStatus
    } = usePaymentStatusService(); 

    const {
        fetchingGetPaymentStatusDetailByPaymentStatus,
        getPaymentStatusDetailsByPaymentStatus
    } = usePaymentStatusDetailService();

    const [statuses, setStatuses] = useState<PaymentStatusesStatus[]>([]);
    const [paymentStatus, setPaymentStatus] = useState<PaymentStatusDataForm>({});
    
    const [paymentStatusDetail, setPaymentStatusDetail] = useState<PaymentStatusDetail[]>([]);


    useEffect(() => {
        reloadModule();
    }, [id]);

    const edit = (id: number) => {
        
        editPaymentStatus(id, {
            onSuccess: (response: ServiceResponse) => {
                setPaymentStatus(response.data.payment_status);
                setStatuses(response.data.statuses)
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message);
                if (response.data.to_dashboard) {
                    navigationPage('/payment-statuses');
                }
            }
        });
    };

    const reloadModule = () => {
        edit(parseInt(id));
    };

    

    const requestChangeStatus = (status: string) => {
        if (showLoading) showLoading('loading', 'Cargando...');
        canChangePaymentStatuses(parseInt(id), status, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                changeStatus(status, response.message);
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message,
                    message_list: response.data?.message_list ?? []
                });
                toast.error(response.message);
            }
        });
    };

    const changeStatus = (status: string, message: string) => {
        const _text = `Está a punto de cambiar el estado del estado de pago #${id}.${message ?? ''}`;
        useReactConfirmAlert().requestConfirmation({
            title: '¿Estás seguro?',
            message: _text,
            buttons: {
                confirmButton: {
                    label: 'Sí, cambiar',
                    onClick: () => {
                        if (showLoading) showLoading('loading', 'Cambiando estado...');
                        changeStatusPaymentStatus(Number(id), status, {
                            onSuccess: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();
                                reloadModule();
                                useReactConfirmAlert().successAlert({
                                    title: 'Éxito',
                                    message: response.message
                                });
                            },
                            onError: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();
                                useReactConfirmAlert().errorAlert({
                                    title: 'Error',
                                    message: response.message,
                                    message_list: response.data?.message_list ?? []
                                });
                            }
                        });
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: 'El estado de pago no ha cambiado de estado.'
                            });
                        }, 0);
                    }
                }
            }
        });
    };

    const breadcrumbs: BreadcrumbsItem[] = [
        { name: 'Estado de pago', url: '/payment-statuses', isActive: false },
        { name: `Editar Estado de pago #${id}`, url: `/payment-statuses/${id}/edit`, isActive: true }
    ];

    return (
        <>
            <Breadcrumbs pageSection={`Editar Estado de pago #${id}`} breadcrumbs={breadcrumbs} />
            <div className="row">
                <div className="col-md-6">
                    <DefaultCard>
                        <div className="h3 mt-0 mb-2 card-title">Datos Generales</div>
                        {!fetchingEditPaymentStatus ? (
                            <PaymentStatusForm
                                paymentStatus={paymentStatus}
                                setPaymentStatus={setPaymentStatus}
                            />
                        ) : (
                            <LazyLoading />
                        )}
                    </DefaultCard>
                </div>
                <div className="col-md-6"></div>
            </div>

            <div className="mt-2">
                <h5>Detalles Estado de Pago</h5>
            </div>
            

            <PaymentStatusDetailContainer
                paymentStatusId={parseInt(id)}
            />
           
            <PaymentChangeStatus
                status={String(paymentStatus?.status)}
                statuses={statuses}
                requestChangeStatus={requestChangeStatus}
                fetchingChangeStatus={fetchingChangeStatusPaymentStatus}
            />
        </>
    );
};

export default PaymentStatusEdit;
