import React from 'react';
import ProviderForm from './ProviderForm';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { Provider } from '../../../app/models/Provider';

interface Props {
    fetching?: boolean;
    action: () => void;
    cancel: () => void;
    provider: Provider;
    setProvider: (provider: Provider) => void;
    providerTypes?: any[];
    errorFields?: any;
}

const ProviderFormContainer = ({
    fetching,
    action,
    cancel,
    provider,
    setProvider,
    providerTypes,
    errorFields = null
}: Props) => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <ProviderForm
                        Provider={provider}
                        providerTypes={providerTypes}
                        setProvider={setProvider}
                        errorFields={errorFields}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetching} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={action} locked={fetching} />
                </div>
            </div>
        </>
    );
};

export default ProviderFormContainer;
