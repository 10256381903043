export enum Roles {
    ADMIN = 'administrador',
    MANAGERS = 'gerentes',
    RUNNER = 'runner',
    OPERATIONS_MANAGER = 'jefe_operaciones'
}

export enum Items {
    PRODUCT = 'PRODUCT',
    SERVICE = 'SERVICE'
}

export enum ServiceManifestStatuses {
    DRAFT = 'DRAFT',
    CONFIRMED = 'CONFIRMED'
}

export enum AccountingStatuses {
    ACCOUNTED = 'ACCOUNTED',
    IN_PROGRESS = 'IN_PROGRESS'
}

export enum ServiceType {
    TRANSPORT = 'TRANSPORT',
    STORAGE = 'STORAGE'
}
