import React from 'react';

type CardProps = {
    children?: React.ReactNode;
};

const DefaultCard = ({ children }: CardProps) => {
    return (
        <div className="card">
            <div className="card-body">{children}</div>
        </div>
    );
};

export default DefaultCard;
