import loader from '../assets/images/loader/loader.svg';
import cargando from '../assets/images/loader/cargando.svg';

type LazyLoadingProps = {
    height?: string | number;
    text?: string;
};

const LazyLoading = ({ height = '100vh', text = 'Cargando..' }: LazyLoadingProps) => {
    let style = {};
    if (height) {
        style = {
            height: height
        };
    }

    return (
        <div className="w-100 d-flex" style={style}>
            <div className="m-auto text-center">
                <img className="py-2" src={loader} alt="eTicket" />
                <br />
                <p className="font-size-14">{text}</p>
                {/*<img src={cargando} alt='eTicket' />*/}
            </div>
        </div>
    );
};

export default LazyLoading;
