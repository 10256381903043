import { Link } from 'react-router-dom';
import {
    InternalSettlement,
    InternalSettlementFilters,
    InternalSettlementTable
} from '../../../../app/models/InternalSettlement';
import ButtonTableAction from '../../../../components/buttons/ButtonTableAction';
import DefaultDataTable from '../../../../components/default/DefaultDataTable';

import { momentParseDate } from '../../../../helpers';
import { formatAmount, formatMoney } from '../../../../utils/utils';
import InternalSettlementFilter from './InternalSettlementFilter';
import LazyLoading from '../../../../components/LazyLoading';
import GetAllSummary from '../GetAllSummary';

interface Props {
    internalSettlements: InternalSettlement[];
    totalRows: number;
    allCredit: number;
    allDebit: number;
    loading?: boolean;
    edit?: (id: number) => void;
    active?: (id: number, active: boolean) => void;
    show?: (id: number) => void;
    destroy?: (id: number) => void;
    filter: InternalSettlementFilters;
    setFilter: (filter: InternalSettlementFilters) => void;
    paginationRowsPerPageOptions?: any[];
    downloadExcel?: () => void;
}

const InternalSettlementDataTable = ({
    internalSettlements,
    totalRows,
    allCredit,
    allDebit,
    loading = false,
    filter,
    setFilter,
    downloadExcel,
    show
}: Props) => {
    const columns = [
        {
            name: 'Id',
            selector: (row: any) => row.id,
            sortable: true,
            sortField: 'id',
            cell: (row: InternalSettlementTable, index: any, column: any) => (
                <span className="">{row.id}</span>
            )
        },
        {
            name: 'Fecha de emisión',
            selector: (row: any) => row?.issue_date,
            sortable: true,
            sortField: 'issue_date',
            cell: (row: InternalSettlementTable, index: any, column: any) => (
                <span className="">{momentParseDate(row?.issue_date)}</span>
            )
        },
        {
            name: 'Tipo de movimiento',
            selector: (row: any) => row.movement_type,
            sortable: true,
            sortField: 'movement_type',
            cell: (row: InternalSettlementTable, index: any, column: any) => (
                <span className="">{row.movement_type}</span>
            )
        },
        {
            name: 'Descripción',
            selector: (row: any) => row.description,
            sortable: true,
            sortField: 'description',
            cell: (row: InternalSettlementTable, index: any, column: any) => (
                <span className="">{row.description}</span>
            )
        },
        {
            name: 'Monto',
            selector: (row: any) => row?.amount,
            sortable: true,
            sortField: 'amount',
            cell: (row: InternalSettlementTable, index: any, column: any) => (
                <>
                    <span className="">
                        {formatAmount(row.amount ? row.amount : 0)} {row.currency}
                    </span>
                </>
            )
        },
        {
            name: 'Acciones',
            selector: (row: any) => row?.id,
            sortable: false,
            width: '150px',
            cell: (row: InternalSettlementTable, index: any, column: any) => (
                <div className="">
                    {show && (
                        <ButtonTableAction
                            callbackFunction={() => show(row?.id ?? -1)}
                            classIcon={'mdi mdi-eye'}
                            colorIcon={'text-info'}
                            errorMessage={'No se puede mostrar este registro.'}
                            title={'Ver gastos'}
                        />
                    )}
                </div>
            )
        }
    ];

    return (
        <>
            <div className="row mt-3">
                <div className="col-lg-6 ms-auto">
                    <GetAllSummary loading={loading} allCredit={allCredit} allDebit={allDebit} />
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-lg-auto">
                    <InternalSettlementFilter filter={filter} setFilter={setFilter} />
                </div>
                <div className="col-auto ms-auto mb-2" style={{ paddingTop: 22 }}>
                    <button className="btn btn-success btn-sm" onClick={downloadExcel}>
                        <i className="fa fa-file-excel" /> Descargar
                    </button>
                </div>
            </div>

            <DefaultDataTable
                columns={columns}
                data={internalSettlements}
                progressPending={loading}
                paginationTotalRows={totalRows}
                filter={filter}
                setFilter={setFilter}
            />
        </>
    );
};

export default InternalSettlementDataTable;
