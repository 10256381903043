import React, { useContext, useEffect, useRef, useState } from 'react';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { AppContext } from '../../../contexts/AppContext';
import { toast } from 'react-toastify';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import {
    CheckingAccountDataForm
} from '../../../app/models/CheckingAccount';
import useCheckingAccountService from '../../../app/services/hooks/useCheckingAccount';
import { Bank } from '../../../app/models/Bank';
import { BankAccountType } from '../../../app/models/BankAccountType';
import { Carrier } from '../../../app/models/Carrier';
import { Currency } from '../../../app/models/Currency';
import CheckingAccountForm from './forms/CheckingAccountForm';
import moment from 'moment';
import { BusinessName } from '../../../app/models/BusinessName';
import { BusinessEntity } from '../../../app/models/BusinessEntity';


interface Props {
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const CheckingAccountCreate = ({ onSaved, onCancel, onError }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);

    const [errorGetData, setErrorGetData] = useState('');
    const [checkingAccount, setCheckingAccount] = useState<CheckingAccountDataForm>({
        issue_date: moment().format('YYYY-MM-DD'),
        sender_currency_id: 0,
        sender_description: '',
        receiver_currency_id: 0,
        receiver_description: ''
    });
    const [senderBusinessNames, setSenderBusinessNames] = useState<BusinessName[]>([]);
    const [senderBusinessEntities, setSenderBusinessEntities] = useState<BusinessEntity[]>([]);
    const [receiverBusinessNames, setReceiverBusinessNames] = useState<BusinessName[]>([]);
    const [receiverBusinessEntities, setReceiverBusinessEntities] = useState<BusinessEntity[]>([]);
    const [senderBanks, setSenderBanks] = useState<Bank[]>([]);
    const [receiverBanks, setReceiverBanks] = useState<Bank[]>([]);
    const [senderBankAccountTypes, setSenderBankAccountTypes] = useState<BankAccountType[]>([]);
    const [receiverBankAccountTypes, setReceiverBankAccountTypes] = useState<BankAccountType[]>([]);
    const [senderCurrencies, setSenderCurrencies] = useState<Currency[]>([]);
    const [receiverCurrencies, setReceiverCurrencies] = useState<Currency[]>([]);
    const [errorFields, setErrorFields] = useState<any>();
   

    const {
        fetchingCreateCheckingAccount,
        fetchingStoreCheckingAccount,
        createCheckingAccount,
        storeCheckingAccount
    } = useCheckingAccountService();

  
    useEffect(() => {
        create();
    }, []);

   
    const create = () => {
        if (showLoading) showLoading('loading', 'Cargando datos...');
        createCheckingAccount({
            onSuccess: (response: ServiceResponse) => {
                setSenderBusinessNames(response.data.business_names);
                setSenderBusinessEntities(response.data.business_entities);
                setReceiverBusinessNames(response.data.business_names);
                setReceiverBusinessEntities(response.data.business_entities);
                setSenderBanks(response.data.banks);
                setReceiverBanks(response.data.banks);
                setSenderBankAccountTypes(response.data.bank_account_types);
                setReceiverBankAccountTypes(response.data.bank_account_types);
                setSenderCurrencies(response.data.currencies);
                setReceiverCurrencies(response.data.currencies);
                
                const currency_id = response.data.currencies.find((currency : any) => String(currency.name).toUpperCase() === 'CLP')?.id ?? 0;
                
                setCheckingAccount({
                    ...checkingAccount,
                    sender_currency_id: currency_id,
                    receiver_currency_id: currency_id
                });
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    };

    const store = () => {
        if (showLoading) showLoading('loading', 'Guardando transferencia...');
        storeCheckingAccount(checkingAccount, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();

                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    if (errorGetData !== '') {
        return <div>{errorGetData}</div>;
    }

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <CheckingAccountForm
                        checkingAccountDataForm={checkingAccount}
                        setCheckingAccountDataForm={setCheckingAccount}
                        senderBusinessNames={senderBusinessNames}
                        senderBusinessEntities={senderBusinessEntities}
                        receiverBusinessNames={receiverBusinessNames}
                        receiverBusinessEntities={receiverBusinessEntities}
                        senderBanks={senderBanks}
                        receiverBanks={receiverBanks}
                        senderBankAccountTypes={senderBankAccountTypes}
                        receiverBankAccountTypes={receiverBankAccountTypes}
                        senderCurrencies={senderCurrencies}
                        receiverCurrencies={receiverCurrencies}
                        errorFields={errorFields}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm
                        callbackCancel={cancel}
                        locked={fetchingStoreCheckingAccount}
                    />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={store} locked={fetchingStoreCheckingAccount} />
                </div>
            </div>
        </>
    );
};

export default CheckingAccountCreate;
