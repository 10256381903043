import React, { useEffect, useState } from 'react';
import { defaultHopper, Hopper } from '../../../app/models/Hopper';
import useHopperService from '../../../app/services/hooks/useHopperService';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import LazyLoading from '../../../components/LazyLoading';
import HopperFormContainer from './HopperFormContainer';
import { Truck } from '../../../app/models/Truck';
import { Carrier } from '../../../app/models/Carrier';

interface Props {
    hopperId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: (message: string) => void;
}

const HopperEdit = ({ hopperId, onSaved, onCancel, onError }: Props) => {
    const { fetchingEditHopper, editHopper, fetchingUpdateHopper, updateHopper } =
        useHopperService();

    const [hopper, setHopper] = useState<Hopper>(defaultHopper);
    const [errorFields, setErrorFields] = useState<any>();
    const [trucks, setTrucks] = useState<Truck[]>([]);
    const [carriers, setCarriers] = useState<Carrier[]>([]);

    useEffect(() => {
        edit();
    }, []);

    const edit = () => {
        editHopper(hopperId, {
            onSuccess: (response: ServiceResponse) => {
                setHopper(response.data.hopper);
                setCarriers(response.data.carriers);
                setTrucks(response.data.trucks);
            },
            onError: (response: ServiceResponse) => {
                if (onError) onError(response.message);
            }
        });
    };

    const update = () => {
        updateHopper(hopperId, hopper, {
            onSuccess: () => {
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (onError) onError(response.message);
            },
            onFieldError: (errorFields: ServiceResponse) => {
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingEditHopper ? (
        <LazyLoading height="300" />
    ) : (
        <HopperFormContainer
            fetching={fetchingUpdateHopper}
            action={update}
            cancel={cancel}
            hopper={hopper}
            setHopper={setHopper}
            errorFields={errorFields}
            carriers={carriers}
            trucks={trucks}
        />
    );
};

export default HopperEdit;
