import React from 'react';
import DefaultCard from '../components/default/DefaultCard';

const NotAuthorizes = () => {
    return (
        <>
            <DefaultCard>
                <div className="row">
                    <div className="col-12 mt-md-0 mt-3">
                        <h1 className="h3">No tienes permisos para acceder a esta página</h1>
                    </div>
                </div>
            </DefaultCard>
        </>
    );
};

export default NotAuthorizes;
