import React from 'react';

const Dashboard = () => {
    return (
        <div className="row">
            {/*<iframe title='El Origen - Duplicado de Página 2' width='1024' height='804'*/}
            {/*src='https://app.powerbi.com/view?r=eyJrIjoiZDNmNjIwNDEtMDFkZi00Nzg1LTk0MzItZjdmZTIzZDVmZmY0IiwidCI6IjM1ZjU5YTcwLTZlMzctNGUwOS1iMTJhLTg4YTU4NDQ1ZGVhZSJ9'*/}
            {/*frameBorder='0' />*/}
            <iframe title="Logistica y Despacho"                 width="1920"
                height="1080"
                    src="https://app.powerbi.com/reportEmbed?reportId=085fe3bb-8eec-4914-973e-e37d1b75883d&autoAuth=true&ctid=b7039a2a-61e2-42d1-b606-516f36697736"
                    frameBorder="0" ></iframe>
        </div>
    );
};
export default Dashboard;
