import ButtonTableAction from '../../../../components/buttons/ButtonTableAction';
import DefaultDataTable from '../../../../components/default/DefaultDataTable';
import { PaymentStatusDataForm, PaymentStatusFilters } from '../../../../app/models/PaymentStatus';
import { momentParseDate} from '../../../../helpers';
import PaymentStatusFilter from './PaymentStatusFilter';

interface Props {
    PaymentStatuses: PaymentStatusDataForm[];
    totalRows: number;
    loading?: boolean;
    edit?: (PaymentStatusId: number) => void;
    show?: (PaymentStatusId: number) => void;
    destroy?: (PaymentStatusId: number) => void;
    documentPaymentStatus?: (paymentStatusId: number) => void;
    filter: PaymentStatusFilters;
    setFilter: (filter: PaymentStatusFilters) => void;
    paginationRowsPerPageOptions?: any[];
}

const PaymentStatusDataTable = ({
    PaymentStatuses,
    totalRows,
    loading = false,
    documentPaymentStatus,
    edit,
    destroy,
    filter,
    setFilter
}: Props) => {
    const columns = [
        {
            name: 'ID',
            selector: (row: any) => row.id,
            sortable: true,
            sortField: 'id',
            cell: (row: PaymentStatusDataForm, index: any, column: any) => (
                <span className="">
                    {' '}
                    {/* Agrega la clase de Bootstrap que desees */}
                    {row.id}
                </span>
            )
        },
        {
            name: 'Cliente',
            selector: (row: any) => row.client,
            sortable: true,
            sortField: 'client',
            cell: (row: PaymentStatusDataForm, index: any, column: any) => (
                <span className="">
                <div
                    dangerouslySetInnerHTML={{
                        __html: row.client ? String(row.client) : ''
                    }}
                />
            </span>
            )
        },
        {
            name: 'Email',
            selector: (row: any) => row.contact_email,
            sortable: true,
            sortField: 'contact_email',
            minWidth: '210px',
            cell: (row: PaymentStatusDataForm, index: any, column: any) => (
                <span className="">{row?.contact_email}</span>
            )
        },
        {
            name: 'Número de contacto',
            selector: (row: any) => row.phone_contact,
            sortable: true,
            sortField: 'contact_phone',
            cell: (row: PaymentStatusDataForm, index: any, column: any) => (
                <span className="">{row?.contact_phone}</span>
            )
        },
        {
            name: 'Condición de pago',
            selector: (row: any) => row.payment_condition,
            sortable: true,
            sortField: 'payment_condition',
            minWidth: '210px',
            cell: (row: PaymentStatusDataForm, index: any, column: any) => (
                <span className="">{row?.payment_condition}</span>
            )
        },

        {
            name: 'creado',
            selector: (row: any) => row?.created_at,
            sortable: true,
            sortField: 'created_at',
            cell: (row: PaymentStatusDataForm, index: any, column: any) => (
                <span className="">{momentParseDate(row?.created_at)}</span>
            )
        },

        {
            name: 'Acciones',
            selector: (row: any) => row.id,
            sortable: false,
            cell: (row: PaymentStatusDataForm, index: any, column: any) => (
                <div className="">
                    {documentPaymentStatus && (
                        <ButtonTableAction
                            callbackFunction={() => documentPaymentStatus(row.id ?? -1)}
                            classIcon={'mdi mdi-download'}
                            colorIcon={'text-success'}
                            errorMessage={'No se puede descargar este registro.'}
                            title={'Descargar'}
                        />

                    
                    )}
                    {edit && (
                        <ButtonTableAction
                            callbackFunction={() => edit(row?.id ?? -1)}
                            classIcon={'mdi mdi-pencil'}
                            colorIcon={'text-warning'}
                            errorMessage={'No se puede editar este registro.'}
                            title={'Editar'}
                        />
                    )}
                    {destroy && (
                        <ButtonTableAction
                            callbackFunction={() => destroy(row?.id ?? -1)}
                            classIcon={'mdi mdi-delete'}
                            colorIcon={'text-danger'}
                            errorMessage={'No se puede eliminar este registro.'}
                            title={'Eliminar'}
                        />
                    )}
                </div>
            )
        }
    ];

    return (
        <>
            <div className="row mt-3">
                <div className="col">
                    <PaymentStatusFilter filter={filter} setFilter={setFilter} />
                </div>
            </div>

            <DefaultDataTable
                columns={columns}
                data={PaymentStatuses}
                progressPending={loading}
                paginationTotalRows={totalRows}
                filter={filter}
                setFilter={setFilter}
            />

            
        </>
    );
};

export default PaymentStatusDataTable;
