import React from 'react';
import ClientForm from './ClientForm';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { Client } from '../../../app/models/Client';

interface Props {
    fetching?: boolean;
    action: () => void;
    cancel: () => void;
    client: Client;
    setClient: (client: Client) => void;
    errorFields?: any;
}

const ClientFormContainer = ({
    fetching,
    action,
    cancel,
    client,
    setClient,
    errorFields = null
}: Props) => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <ClientForm client={client} setClient={setClient} errorFields={errorFields} />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetching} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={action} locked={fetching} />
                </div>
            </div>
        </>
    );
};

export default ClientFormContainer;
