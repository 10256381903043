import React, { useContext, useEffect, useRef, useState } from 'react';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import LazyLoading from '../../../components/LazyLoading';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { toast } from 'react-toastify';
import { BankAccountType } from '../../../app/models/BankAccountType';
import { Carrier } from '../../../app/models/Carrier';
import { Currency } from '../../../app/models/Currency';
import CheckingAccountFormContainer from './CheckingAccountFormContainer';
import moment from 'moment';
import useCheckingAccountService from '../../../app/services/hooks/useCheckingAccount';
import { CheckingAccountDataForm } from '../../../app/models/CheckingAccount';
import { Bank } from '../../../app/models/Bank';
import { BusinessName } from '../../../app/models/BusinessName';
import { BusinessEntity } from '../../../app/models/BusinessEntity';


interface Props {
    checkingAccountId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const CheckingAccountEdit = ({ checkingAccountId, onSaved, onCancel, onError }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const {
        fetchingEditCheckingAccount,
        editCheckingAccount,
        fetchingUpdateCheckingAccount,
        updateCheckingAccount
    } = useCheckingAccountService();

    const [checkingAccount, setCheckingAccount] = useState<CheckingAccountDataForm>({
        issue_date: moment().format('YYYY-MM-DD'),
        sender_currency_id: 0,
        sender_description: '',
        receiver_currency_id: 0,
        receiver_description: ''
    });
    const [senderBusinessNames, setSenderBusinessNames] = useState<BusinessName[]>([]);
    const [senderBusinessEntities, setSenderBusinessEntities] = useState<BusinessEntity[]>([]);
    const [receiverBusinessNames, setReceiverBusinessNames] = useState<BusinessName[]>([]);
    const [receiverBusinessEntities, setReceiverBusinessEntities] = useState<BusinessEntity[]>([]);
    const [senderBanks, setSenderBanks] = useState<Bank[]>([]);
    const [receiverBanks, setReceiverBanks] = useState<Bank[]>([]);
    const [senderBankAccountTypes, setSenderBankAccountTypes] = useState<BankAccountType[]>([]);
    const [receiverBankAccountTypes, setReceiverBankAccountTypes] = useState<BankAccountType[]>([]);
    const [senderCurrencies, setSenderCurrencies] = useState<Currency[]>([]);
    const [receiverCurrencies, setReceiverCurrencies] = useState<Currency[]>([]);
    const [errorFields, setErrorFields] = useState<any>();
  

    useEffect(() => {
        edit();
    }, []);

 

    const edit = () => {
        if (showLoading) showLoading('loading', 'Cargando transferencia...');
        editCheckingAccount(checkingAccountId, {
            onSuccess: (response: ServiceResponse) => {

                setSenderBusinessNames(response.data.sender_business_names);
                setSenderBusinessEntities(response.data.business_entities);
                setReceiverBusinessNames(response.data.receiver_business_names);
                setReceiverBusinessEntities(response.data.business_entities);
                setSenderBanks(response.data.sender_banks);
                setReceiverBanks(response.data.receiver_banks);
                setSenderBankAccountTypes(response.data.sender_bank_account_types);
                setReceiverBankAccountTypes(response.data.receiver_bank_account_types);
                setSenderCurrencies(response.data.sender_currencies);
                setReceiverCurrencies(response.data.receiver_currencies);
                setCheckingAccount(response.data.checking_account);

                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    };

    const update = () => {
        if (showLoading) showLoading('loading', 'Actualizando transferencia...');
        updateCheckingAccount(checkingAccountId, checkingAccount, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingEditCheckingAccount ? (
        <LazyLoading height="300" />
    ) : (
        <CheckingAccountFormContainer
            fetching={fetchingUpdateCheckingAccount}
            action={update}
            cancel={cancel}
            checkingAccount={checkingAccount}
            setCheckingAccount={setCheckingAccount}
            senderBusinessNames={senderBusinessNames}
            senderBusinessEntities={senderBusinessEntities}
            receiverBusinessNames={receiverBusinessNames}
            receiverBusinessEntities={receiverBusinessEntities}
            senderBanks={senderBanks}
            receiverBanks={receiverBanks}
            senderBankAccountTypes={senderBankAccountTypes}
            receiverBankAccountTypes={receiverBankAccountTypes}
            senderCurrencies={senderCurrencies}
            receiverCurrencies={receiverCurrencies}
            errorFields={errorFields}
        />
    );
};

export default CheckingAccountEdit;
