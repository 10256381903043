import React from 'react';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import CheckingAccountForm from './forms/CheckingAccountForm';
import { Currency } from '../../../app/models/Currency';
import { Bank } from '../../../app/models/Bank';
import { CheckingAccountDataForm } from '../../../app/models/CheckingAccount';
import { BankAccountType } from '../../../app/models/BankAccountType';
import { BusinessName } from '../../../app/models/BusinessName';
import { BusinessEntity } from '../../../app/models/BusinessEntity';


interface Props {
    fetching?: boolean;
    action: () => void;
    cancel: () => void;
    checkingAccount: CheckingAccountDataForm;
    setCheckingAccount: (checkingAccount: CheckingAccountDataForm) => void;
    senderBusinessNames: BusinessName[];
    senderBusinessEntities: BusinessEntity[];
    receiverBusinessNames: BusinessName[];
    receiverBusinessEntities: BusinessEntity[];
    senderBanks: Bank[];
    receiverBanks: Bank[];
    senderBankAccountTypes: BankAccountType[];
    receiverBankAccountTypes: BankAccountType[];
    senderCurrencies: Currency[];
    receiverCurrencies: Currency[];
    errorFields?: any;
}

const CheckingAccountFormContainer = ({
    fetching,
    action,
    cancel,
    checkingAccount,
    setCheckingAccount,
    senderBusinessNames,
    senderBusinessEntities,
    receiverBusinessNames,
    receiverBusinessEntities,
    senderBanks,
    receiverBanks,
    senderBankAccountTypes,
    receiverBankAccountTypes,
    senderCurrencies,
    receiverCurrencies,
    errorFields = null,
 }: Props) => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <CheckingAccountForm
                        checkingAccountDataForm={checkingAccount}
                        setCheckingAccountDataForm={setCheckingAccount}
                        senderBusinessNames={senderBusinessNames}
                        senderBusinessEntities={senderBusinessEntities}
                        receiverBusinessNames={receiverBusinessNames}
                        receiverBusinessEntities={receiverBusinessEntities}
                        senderBanks={senderBanks}
                        receiverBanks={receiverBanks}
                        senderBankAccountTypes={senderBankAccountTypes}
                        receiverBankAccountTypes={receiverBankAccountTypes}
                        senderCurrencies={senderCurrencies}
                        receiverCurrencies={receiverCurrencies}
                        errorFields={errorFields}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetching} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={action} locked={fetching} />
                </div>
            </div>
        </>
    );
};

export default CheckingAccountFormContainer;
