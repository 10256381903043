import React, { useContext, useEffect, useRef, useState } from 'react';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { useHistory } from 'react-router-dom';

import { DailyCarrier, DailyCarrierFilters } from '../../../app/models/DailyCarrier';
import { ServiceManifestDataForm } from '../../../app/models/ServiceManifest';
import PurchaseOrderDetailOnDispatchProgramDataTable from './tables/PurchaseOrderDetailOnDispatchProgramDataTable';
import { PurchaseOrderDetailDataForm, PurchaseOrderDetailFilters } from '../../../app/models/PurchaseOrderDetail';
import usePurchaseOrderDetailService from '../../../app/services/hooks/usePurchaseOrderDetailService';
import { toast } from 'react-toastify';
import useServiceManifestService from '../../../app/services/hooks/useServiceManifestService';

import { Tab, Tabs } from 'react-bootstrap';
import DailyCarrierDataTable from '../../DailyCarriers/components/DailyCarrierDataTable';
import useDailyCarrierService from '../../../app/services/hooks/useDailyCarrierService';
import ServiceManifestForm from './forms/ServiceManifestForm';
import useNavigationPage from '../../../hooks/useNavigationPage';

interface Props {
    dispatch_program_id: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const ServiceManifestCreateOnDispatchProgram = ({
    dispatch_program_id,
    onSaved,
    onCancel,
    onError
}: Props) => {

    const { navigationPage } = useNavigationPage();
    const { showLoading, hideLoading, changeAnimation, showProgressiveLoading } =
        useContext(AppContext);
    const history = useHistory();
    const {
        fetchingGetPurchaseOrderDetailsFinished, 
        getPurchaseOrderDetailsFinished
    } = usePurchaseOrderDetailService();
    const {
        fetchingStoreServiceManifestOnDispatchProgram,
        storeServiceManifestOnDispatchProgram,
        fetchingDeleteServiceManifestByDispatchProgram,
        deleteServiceManifestByDispatchProgram,
        goServiceManifest,
        fetchingGoServiceManifest
    } = useServiceManifestService()
    const {
        getDailyCarriersWithServiceManifest,
        fetchingGetDailyCarriersWithServiceManifest,
    } = useDailyCarrierService()

    const [dailyCarriers, setDailyCarriers] = useState<DailyCarrier[]>([]);
    const [dailyCarriersWithServiceManifest, setDailyCarriersWithServiceManifest] = useState<DailyCarrier[]>([]);
    const [serviceManifest, setServiceManifest] = useState<ServiceManifestDataForm>(
        {
            dispatch_program_id: dispatch_program_id,
            total_guide: 0,
            daily_carrier: ''
        }
    );
    const [purchaseOrderDetails, setPurchaseOrderDetails] = useState<PurchaseOrderDetailDataForm[]>([]);
    const [totalRows, setTotalRows] = useState(0);
    const [dailyCarrierTotalRows, setDailyCarrierTotalRows] = useState(0);
    const [productName, setPrductName] = useState<string>('');
    
    const [errorFields, setErrorFields] = useState<any>();
    // const hasServiceManifest = useRef<boolean>(false);

    const [filter, setFilter] = useState<PurchaseOrderDetailFilters>({
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'desc'
    });
    const [dailyCarrierFilter, setDailyCarrierFilter] = useState<DailyCarrierFilters>({
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'desc'
    });

    const [rows, setRows] = useState<any[]>([]);

    const [toggleCleared, setToggleCleared] = useState<boolean>(false);

    const resetContextActions = () => {

        setToggleCleared(!toggleCleared);
     
    };

    useEffect(() => {

        reloadTable();

    }, [filter]);

    useEffect(() => {

        reloadDailyCarrierTable();

    }, [dailyCarrierFilter]);


    const resetForm = () => {
        setServiceManifest(
            {
                ...serviceManifest,
                daily_carrier_id: undefined,
                purchase_order_detail_id: undefined,
                total_guide: 0,
                issue_date: '',
                daily_carrier: ''
            }
        )
    }

    const reloadTable = () => {
        getPurchaseOrderDetailsFinished(filter, dispatch_program_id, {
            onSuccess: (response: ServiceResponse) => {
                
                setErrorFields(undefined);
                resetForm();
                resetContextActions();
                
                setPurchaseOrderDetails(response.data.purchase_order_details);
                setTotalRows(response.data.total_rows);
                setDailyCarriers(response.data.daily_carriers);
                setPrductName(response.data.product);
            },
            onError(response: ServiceResponse) {
                toast.error(response.message);
            },
        });
    };

    const reloadDailyCarrierTable = () => {
        getDailyCarriersWithServiceManifest(dailyCarrierFilter, dispatch_program_id, {
            onSuccess: (response: ServiceResponse) => {
                
                setDailyCarriersWithServiceManifest(response.data.daily_carriers);
                setDailyCarrierTotalRows(response.data.total_rows);
               
            },
            onError(response: ServiceResponse) {
                toast.error(response.message);
            },
        });
    };

    const showServiceManifest = (dailyCarrierId: number) => {

        if (showLoading) showLoading('loading', 'Buscando hoja de entrada...');
        goServiceManifest(dailyCarrierId, dispatch_program_id, {
            onSuccess: (response: ServiceResponse) => {

                if (hideLoading) hideLoading();

                navigationPage('/service-manifests/' + response.data.service_manifest_id + '/show');

            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
                toast.error(response.message);
            }
        });
    };

    const store = () => {
        if (showLoading) showLoading('loading', 'Generando hoja de entrada...');
        storeServiceManifestOnDispatchProgram(serviceManifest, {
            onSuccess: (response: ServiceResponse) => {

                if (hideLoading) hideLoading();
                setErrorFields(undefined);
                resetForm();
                toast.success(response.message);
                reloadTable();
                reloadDailyCarrierTable();
                if (onSaved) onSaved();
                
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
                toast.error(response.message);
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

   
    const destroy = () => {

        const _text = 'Está a punto de eliminar la hoja de entrada del transportista '+serviceManifest.daily_carrier

        useReactConfirmAlert().requestConfirmation({
            title: '¿Estás seguro?',
            message: _text,
            buttons: {
                confirmButton: {
                    label: 'Si, confirmar',
                    onClick: () => {
                        if (showLoading) showLoading('loading', 'Eliminando hoja de entrada...');
                        deleteServiceManifestByDispatchProgram(serviceManifest, {
                            onSuccess: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().successAlert({
                                    title: 'Éxito',
                                    message: response.message
                                });

                                reloadTable();
                                reloadDailyCarrierTable();
                            },
                            onError: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().errorAlert({
                                    title: 'Error',
                                    message: response.message
                                });
                            }
                        });
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: 'La hoja de entrada no se ha eliminado.'
                            });
                        }, 0);
                    }
                }
            }
        });
    };

    return (
        <>
            <div className="row mb-4">
                <div className="col-12">
                    <ServiceManifestForm
                        form={serviceManifest} 
                        setForm={setServiceManifest}
                        dailyCarriers={dailyCarriers}
                        errorFields={errorFields} 
                        deleteServiceManifest={destroy}
                    />
                    <hr />
                </div>
            </div>
            <Tabs defaultActiveKey="generate-data" id="service-manifest-tabs" className="mt-3 mb-3">
                <Tab eventKey="generate-data" title="Generar hojas de entrada">
                <>
                    
                    <div className="row justify-content-between align-items-center">

                        <div className="col-auto">
                            <input
                                name="product"
                                id="product"
                                type="text"
                                className="form-control"
                                value={productName}
                                readOnly={true}
                            />
                        </div>
                        
                        <div className="col-auto mt-2">
                            <ButtonSaveForm title='Generar' callbackSave={store} locked={fetchingStoreServiceManifestOnDispatchProgram} />
                        </div>
                    </div>
                    <hr />
                    <PurchaseOrderDetailOnDispatchProgramDataTable
                        form= {serviceManifest}
                        setForm={setServiceManifest}
                        purchaseOrderDetails={purchaseOrderDetails}
                        loading={fetchingGetPurchaseOrderDetailsFinished}
                        totalRows={totalRows}
                        setFilter={setFilter}
                        filter={filter}
                        toggleCleared={toggleCleared}
                        resetContextActions={resetContextActions}
                    />
            
                </>  
                </Tab>
                <Tab eventKey="Transportistas con hojas de entrada" title="Transportistas con hojas de entrada">

                    <DailyCarrierDataTable
                        dailyCarriers={dailyCarriersWithServiceManifest}
                        loading={fetchingGetDailyCarriersWithServiceManifest}
                        totalRows={dailyCarrierTotalRows}
                        setFilter={setDailyCarrierFilter}
                        filter={dailyCarrierFilter}
                        showServiceManifest={showServiceManifest}
                    />
                </Tab>
           </Tabs>
        </>
    );
};

export default ServiceManifestCreateOnDispatchProgram;
