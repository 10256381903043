import React, { useContext, useEffect, useState } from 'react';

import { Provider, ProviderFilters } from '../../app/models/Provider';
import { ServiceResponse } from '../../app/services/shared/interfaces';
import Breadcrumbs, { BreadcrumbsItem } from '../../template/MainTheme/components/Breadcrumbs';
import DefaultCard from '../../components/default/DefaultCard';
import useProviderService from '../../app/services/hooks/useProviderService';
import DefaultModal from '../../components/default/DefaultModal';
import DefaultToolBar from '../../components/default/DefaultToolBar';
import ButtonCreate from '../../components/buttons/ButtonCreate';
import useSweetAlert from '../../hooks/useSweetAlert';
import { toast } from 'react-toastify';
import ProviderCreate from './components/ProviderCreate';
import ProviderEdit from './components/ProviderEdit';
import ProviderDataTable from './components/ProviderDataTable';
import useReactConfirmAlert from '../../hooks/useReactConfirmAlert';
import { AppContext } from '../../contexts/AppContext';

const breadcrumbs: BreadcrumbsItem[] = [
    {
        name: 'Proveedores',
        url: '/Providers',
        isActive: true
    }
];

const Providers = () => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { fetchingGetProviders, getProviders, deleteProvider } = useProviderService();
    const [providers, setProviders] = useState<Provider[]>([]);

    const [providerIdEdit, setProviderIdEdit] = useState<number>(-1);
    const [showingEdit, setShowingEdit] = useState(false);
    const [showingCreate, setShowingCreate] = useState(false);
    const [totalRows, setTotalRows] = useState(0);

    const [filter, setFilter] = useState<ProviderFilters>({
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'desc'
    });

    useEffect(() => {
        reloadTable();
    }, [filter]);

    const reloadTable = () => {
        getProviders(filter, {
            onSuccess: (response: ServiceResponse) => {
                setProviders(response.data.providers);
                setTotalRows(response.data.total_rows);
            }
        });
    };

    const showEdit = (ProviderId: number) => {
        setShowingEdit(true);
        setProviderIdEdit(ProviderId);
    };

    const hideEdit = () => {
        setShowingEdit(false);
        setProviderIdEdit(-1);
    };

    const showCreate = () => {
        setShowingCreate(true);
    };

    const hideCreate = () => {
        setShowingCreate(false);
    };

    const onError = () => {
        setShowingEdit(false);
        setProviderIdEdit(-1);
    };

    const destroy = (ProviderId: number) => {
        const _text = 'Está a punto de eliminar el proveedor';

        useSweetAlert().requestConfirmation({
            title: '¿Estás seguro?',
            text: _text,
            confirmButtonText: 'Si, eliminar',
            cancelButtonText: 'Cancelar',
            onConfirm: () => {
                useSweetAlert().spinnerAlert('Eliminando proveedor...');
                deleteProvider(ProviderId, {
                    onSuccess: () => {
                        useSweetAlert().successAlert({
                            title: 'Éxito',
                            text: 'Se ha eliminado el proveedor'
                        });
                        reloadTable();
                    },
                    onError: () => {
                        useSweetAlert().errorAlert({
                            title: 'Error',
                            text: 'No se ha podido eliminar el proveedor'
                        });
                    }
                });
            },
            onCancel: () => {
                useSweetAlert().infoAlert({
                    title: 'Cancelado',
                    text: 'El proveedor no se ha eliminado'
                });
            }
        });

        // useReactConfirmAlert().requestConfirmation({
        //     title : '¿Estás seguro?',
        //     message :_text,
        //     buttons : {
        //         confirmButton:{
        //             'label' : 'Si, confirmar',
        //             onClick: () => {
        //                 if(showLoading)showLoading('loading', 'Eliminando proveedor...');
        //                 deleteProvider(ProviderId, {
        //                     onSuccess: (response: ServiceResponse) => {
        //                         if(hideLoading)hideLoading();

        //                         useReactConfirmAlert().successAlert({
        //                             title: 'Éxito',
        //                             message: response.message
        //                         });

        //                         reloadTable();
        //                     },
        //                     onError: (response: ServiceResponse) => {
        //                         if(hideLoading)hideLoading();

        //                         useReactConfirmAlert().errorAlert({
        //                             title: 'Error',
        //                             message: response.message
        //                         });
        //                     }
        //                 });
        //             },
        //         },
        //         cancelButton:{
        //             'label': 'No, cancelar',
        //             onClick: () => {

        //                 setTimeout(() => {
        //                     useReactConfirmAlert().infoAlert({
        //                         title: 'Cancelado',
        //                         message: 'El proveedor no se ha eliminado.'
        //                     });

        //                 }, 0);

        //             },
        //         },

        // }
        // })
    };

    return (
        <>
            <Breadcrumbs pageSection="Proveedores" breadcrumbs={breadcrumbs} />

            <DefaultCard>
                <DefaultToolBar
                    left={<ButtonCreate callbackCreate={showCreate} title="Nuevo proveedor" />}
                />

                <ProviderDataTable
                    providers={providers}
                    totalRows={totalRows}
                    loading={fetchingGetProviders}
                    destroy={destroy}
                    edit={showEdit}
                    filter={filter}
                    setFilter={setFilter}
                />
            </DefaultCard>

            {showingCreate ? (
                <DefaultModal
                    show={showingCreate}
                    handleClose={hideCreate}
                    title="Crear proveedor"
                    backdrop={true}
                    showFooter={false}
                >
                    <ProviderCreate
                        onSaved={() => {
                            reloadTable();
                            hideCreate();
                        }}
                        onCancel={hideCreate}
                    />
                </DefaultModal>
            ) : null}

            {showingEdit ? (
                <DefaultModal
                    show={showingEdit}
                    handleClose={hideEdit}
                    title="Editar Proveedor"
                    backdrop={true}
                    showFooter={false}
                >
                    <ProviderEdit
                        providerId={providerIdEdit}
                        onSaved={() => {
                            reloadTable();
                            hideEdit();
                        }}
                        onCancel={hideEdit}
                        onError={onError}
                    />
                </DefaultModal>
            ) : null}
        </>
    );
};

export default Providers;
