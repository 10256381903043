import React, { useEffect, useState } from 'react';

type AlertBootstrapProps = {
    children: JSX.Element | JSX.Element[] | string;
    type?: string;
    whitIcon?: boolean;
};

const AlertBootstrap = ({ children, type = 'primary', whitIcon = false }: AlertBootstrapProps) => {
    const [icon, setIcon] = useState('mdi mdi-bullseye-arrow me-2');
    const [colorClass, setColorClass] = useState('primary');

    useEffect(() => {
        handleType(type);
    }, [type]);

    const handleType = (type: string) => {
        switch (type) {
            case 'primary':
                setColorClass('primary');
                setIcon('mdi mdi-bullseye-arrow me-2');
                break;
            case 'secondary':
                setColorClass('secondary');
                setIcon('mdi mdi-grease-pencil me-2');
                break;
            case 'success':
                setColorClass('success');
                setIcon('mdi mdi-check-all me-2');
                break;
            case 'danger':
                setColorClass('danger');
                setIcon('mdi mdi-block-helper me-2');
                break;
            case 'warning':
                setColorClass('warning');
                setIcon('mdi mdi-alert-outline me-2');
                break;
            case 'info':
                setColorClass('info');
                setIcon('mdi mdi-alert-circle-outline me-2');
                break;
        }
    };

    return (
        <div className={`alert alert-${colorClass}`} role="alert">
            {whitIcon ? <i className={icon} /> : null}
            {children}
        </div>
    );
};

export default AlertBootstrap;
