import React, { useEffect, useRef } from 'react';
import { Carrier } from '../../../app/models/Carrier';
import useHandleErrorFields from '../../../hooks/useHandleErrorFields';
import useRut from '../../../hooks/useRut';
import InputButtonFile from '../../../components/inputs/InputButtonFile';

type Props = {
    carrier: Carrier;
    setCarrier: (carrier: Carrier) => void;
    errorFields?: any;
};

const CarrierForm = ({ carrier, setCarrier, errorFields }: Props) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();
    const { formatRut } = useRut();

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
    ) => {
        const { name, value } = e.target;
        if (name == 'rut') {
            setCarrier({ ...carrier, [name]: formatRut(value) });
        } else {
            setCarrier({ ...carrier, [name]: value });
        }
    };

    const handleChangeSelectTwo = (name: string, value: number | string | null) => {
        setCarrier({ ...carrier, [name]: value });
    };

    const handleChangeDocumentFile = (e: FileList | null, type: string) => {
        const _carrier = carrier;
        const index = _carrier.documents?.findIndex((document) => document.type === type);
        if (e) {
            if (index !== undefined && index !== null && index >= 0 && _carrier.documents) {
                _carrier.documents[index].file = e[0];
            } else {
                _carrier.documents?.push({
                    type: type,
                    file: e[0]
                });
            }
            setCarrier({
                ..._carrier
            });
        }
    };

    const handleChangeDocumentExpireDate = (
        e: React.ChangeEvent<HTMLInputElement>,
        type: string
    ) => {
        const _carrier = carrier;
        const index = _carrier.documents?.findIndex((document) => document.type === type);
        const { value } = e.target;

        if (index !== undefined && index !== null && index >= 0 && _carrier.documents) {
            _carrier.documents[index].expire_date = value;
        } else {
            _carrier.documents?.push({
                type: type,
                expire_date: value
            });
        }

        setCarrier({
            ..._carrier
        });
    };

    const handleChangeDocumentExpireDateIndefinite = (type: string) => {
        const _carrier = carrier;
        const index = _carrier.documents?.findIndex((document) => document.type === type);

        if (index !== undefined && index !== null && index >= 0 && _carrier.documents) {
            _carrier.documents[index].is_expire_date_indefinite =
                !_carrier.documents[index].is_expire_date_indefinite;
        } else {
            _carrier.documents?.push({
                type: type,
                is_expire_date_indefinite: true
            });
        }

        setCarrier({
            ..._carrier
        });
    };

    return (
        <>
            <div className="mb-2 row needs-validation">
                <label className="col-md-3 col-form-label">Nombre</label>
                <div className="col-md-9">
                    <input
                        name="name"
                        id="name"
                        type="text"
                        className={`form-control ${fieldHasError('name')}`}
                        value={carrier.name}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('name')}
                        placeholder="Ingrese el nombre del conductor"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('name')}
                    </div>
                </div>
            </div>
            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Email</label>
                <div className="col-md-9">
                    <input
                        name="email"
                        id="email"
                        type="email"
                        className={`form-control ${fieldHasError('email')}`}
                        value={carrier.email}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('email')}
                        placeholder="Ingrese el email del conductor"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('email')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Rut</label>
                <div className="col-md-9">
                    <input
                        name="rut"
                        id="rut"
                        type="text"
                        className="form-control"
                        value={carrier.rut}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('rut')}
                        placeholder="Ingrese el RUT del conductor"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('rut')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Teléfono</label>
                <div className="col-md-2 ">
                    <select
                        className="form-control form-control-custom pl-2"
                        id="phone_code"
                        name="phone_code"
                        onChange={handleChange}
                        value={carrier.phone_code}
                        onFocus={() => onFocusRemove('phone_code')}
                    >
                        <option defaultValue={'+56'} value="+56">
                            +56
                        </option>
                    </select>
                </div>
                <div className="col-md-7 ">
                    <input
                        name="phone"
                        id="phone"
                        type="number"
                        className="form-control"
                        value={carrier.phone}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('phone')}
                        placeholder="Ingrese el teléfono del conductor"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('phone')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Dirección</label>
                <div className="col-md-9">
                    <input
                        name="address"
                        id="address"
                        type="text"
                        className="form-control"
                        value={carrier.address}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('address')}
                        placeholder="Ingrese la dirección del conductor"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('address')}
                    </div>
                </div>
            </div>

            <hr />

            <div className="mb-2 row">
                <div className="col-md-6 ">
                    <label className="col-md-12 col-form-label">Carnet de identidad</label>
                    <InputButtonFile
                        name={'CARNET_DE_INDENTIDAD'}
                        handleChangeDocumentFile={handleChangeDocumentFile}
                        objectFile={
                            carrier.documents?.find((item) => item.type === 'CARNET_DE_INDENTIDAD')
                                ?.file
                        }
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('CARNET_DE_INDENTIDAD')}
                    </div>
                </div>
                <div className="col-md-6 ">
                    <label className="col-md-12 col-form-label">Fecha de vencimiento Carnet</label>
                    <input
                        name="CARNET_DE_INDENTIDAD_expire_date"
                        id="CARNET_DE_INDENTIDAD_expire_date"
                        type="date"
                        className={`form-control ${fieldHasError(
                            'CARNET_DE_INDENTIDAD_expire_date'
                        )}`}
                        value={
                            carrier.documents?.find((item) => item.type === 'CARNET_DE_INDENTIDAD')
                                ?.expire_date
                        }
                        onChange={(e) => handleChangeDocumentExpireDate(e, 'CARNET_DE_INDENTIDAD')}
                        onFocus={() => onFocusRemove('CARNET_DE_INDENTIDAD_expire_date')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('CARNET_DE_INDENTIDAD_expire_date')}
                    </div>
                </div>
            </div>
            <div className="mb-2 row">
                <div className="col-md-6 ">
                    <label className="col-md-12 col-form-label">Licencia de conducir</label>
                    <InputButtonFile
                        name={'LICENCIA_DE_CONDUCCIR'}
                        handleChangeDocumentFile={handleChangeDocumentFile}
                        objectFile={
                            carrier.documents?.find((item) => item.type === 'LICENCIA_DE_CONDUCCIR')
                                ?.file
                        }
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('LICENCIA_DE_CONDUCCIR')}
                    </div>
                </div>
                <div className="col-md-6 ">
                    <label className="col-md-12 col-form-label">
                        Fecha de vencimiento licencia
                    </label>
                    <input
                        name="LICENCIA_DE_CONDUCCIR_expire_date"
                        id="LICENCIA_DE_CONDUCCIR_expire_date"
                        type="date"
                        className={`form-control ${fieldHasError(
                            'LICENCIA_DE_CONDUCCIR_expire_date'
                        )}`}
                        value={
                            carrier.documents?.find((item) => item.type === 'LICENCIA_DE_CONDUCCIR')
                                ?.expire_date
                        }
                        onChange={(e) => handleChangeDocumentExpireDate(e, 'LICENCIA_DE_CONDUCCIR')}
                        onFocus={() => onFocusRemove('LICENCIA_DE_CONDUCCIR_expire_date')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('LICENCIA_DE_CONDUCCIR_expire_date')}
                    </div>
                </div>
            </div>
            <div className="mb-2 row">
                <div className="col-md-6 ">
                    <label className="col-md-12 col-form-label">Examenes pre ocupacionales</label>
                    <InputButtonFile
                        name={'EXAMENES_PRE_OCUPACIONALES'}
                        handleChangeDocumentFile={handleChangeDocumentFile}
                        objectFile={
                            carrier.documents?.find(
                                (item) => item.type === 'EXAMENES_PRE_OCUPACIONALES'
                            )?.file
                        }
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('EXAMENES_PRE_OCUPACIONALES')}
                    </div>
                </div>
                <div className="col-md-6 ">
                    <label className="col-md-12 col-form-label">
                        Fecha de vencimiento examenes
                    </label>
                    <input
                        name="EXAMENES_PRE_OCUPACIONALES_expire_date"
                        id="EXAMENES_PRE_OCUPACIONALES_expire_date"
                        type="date"
                        className={`form-control ${fieldHasError(
                            'EXAMENES_PRE_OCUPACIONALES_expire_date'
                        )}`}
                        value={
                            carrier.documents?.find(
                                (item) => item.type === 'EXAMENES_PRE_OCUPACIONALES'
                            )?.expire_date
                        }
                        onChange={(e) =>
                            handleChangeDocumentExpireDate(e, 'EXAMENES_PRE_OCUPACIONALES')
                        }
                        onFocus={() => onFocusRemove('EXAMENES_PRE_OCUPACIONALES_expire_date')}
                    />

                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('EXAMENES_PRE_OCUPACIONALES_expire_date')}
                    </div>
                </div>
            </div>
            <div className="mb-2 row">
                <div className="col-md-6 ">
                    <label className="col-md-12 col-form-label">Contrato</label>
                    <InputButtonFile
                        name={'CONTRATO'}
                        handleChangeDocumentFile={handleChangeDocumentFile}
                        objectFile={
                            carrier.documents?.find((item) => item.type === 'CONTRATO')?.file
                        }
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('CONTRATO')}
                    </div>
                </div>
                <div className="col-md-6 ">
                    <label className="col-md-12 col-form-label">
                        Fecha de vencimiento contrato
                    </label>
                    <input
                        name="CONTRATO_expire_date"
                        id="CONTRATO_expire_date"
                        type="date"
                        className={`form-control ${fieldHasError('CONTRATO_expire_date')}`}
                        value={
                            carrier.documents?.find((item) => item.type === 'CONTRATO')?.expire_date
                        }
                        onChange={(e) => handleChangeDocumentExpireDate(e, 'CONTRATO')}
                        onFocus={() => onFocusRemove('CONTRATO_expire_date')}
                    />
                    <button
                        className="btn-primary"
                        onClick={() => handleChangeDocumentExpireDateIndefinite('CONTRATO')}
                    >
                        ¿Indefinidio?
                        <strong>
                            {carrier.documents?.find((item) => item.type === 'CONTRATO')
                                ?.is_expire_date_indefinite
                                ? ' Si'
                                : ' No'}
                        </strong>
                    </button>
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('CONTRATO_expire_date')}
                    </div>
                </div>
            </div>
            <div className="mb-2 row">
                <div className="col-md-6 ">
                    <label className="col-md-12 col-form-label">Anexo</label>
                    <InputButtonFile
                        name={'ANEXO'}
                        handleChangeDocumentFile={handleChangeDocumentFile}
                        objectFile={carrier.documents?.find((item) => item.type === 'ANEXO')?.file}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('ANEXO')}
                    </div>
                </div>
                <div className="col-md-6 ">
                    <label className="col-md-12 col-form-label">Fecha de vencimiento anexo</label>
                    <input
                        name="ANEXO_expire_date"
                        id="ANEXO_expire_date"
                        type="date"
                        className={`form-control ${fieldHasError('ANEXO_expire_date')}`}
                        value={
                            carrier.documents?.find((item) => item.type === 'ANEXO')?.expire_date
                        }
                        onChange={(e) => handleChangeDocumentExpireDate(e, 'ANEXO')}
                        onFocus={() => onFocusRemove('ANEXO_expire_date')}
                    />
                    <button
                        className="btn-primary"
                        onClick={() => handleChangeDocumentExpireDateIndefinite('ANEXO')}
                    >
                        ¿Indefinidio?
                        <strong>
                            {carrier.documents?.find((item) => item.type === 'ANEXO')
                                ?.is_expire_date_indefinite
                                ? ' Si'
                                : ' No'}
                        </strong>
                    </button>
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('ANEXO_expire_date')}
                    </div>
                </div>
            </div>
        </>
    );
};

export default CarrierForm;
