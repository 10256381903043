const useRut = () => {
    const formatRut = (rut: string) => {
        const current = rut.replace(/^0+/, '');

        if (current != '' && current.length > 1) {
            const unformatRut = unFormatRut(current);
            const cleanRut = unformatRut.substring(0, unformatRut.length - 1);

            // let rutNumberFormat = new Intl.NumberFormat('de-DE').format(cleanRut)

            let rutNumberFormat = '';

            let i = 0;
            let j = 1;

            for (i = cleanRut.length - 1; i >= 0; i--) {
                rutNumberFormat = cleanRut.charAt(i) + rutNumberFormat;
                if (j % 3 == 0 && j <= cleanRut.length - 1) {
                    rutNumberFormat = '.' + rutNumberFormat;
                }
                j++;
            }

            const dv = unformatRut.substring(unformatRut.length - 1);
            rutNumberFormat = rutNumberFormat + '-' + dv;
            return rutNumberFormat;
        }
        return current;
    };

    const unFormatRut = (rut: string) => {
        return rut.replace(/\./g, '').replace(/-/g, '');
    };

    const validateRut = (rut: string) => {
        const cleanRut = unFormatRut(rut);

        const body = cleanRut.slice(0, -1);
        let dv = cleanRut.slice(-1).toUpperCase();

        if (body.length < 7) return false;

        let plus = 0;
        let multiple = 2;

        for (let i = 1; i <= body.length; i++) {
            plus = plus + multiple * parseInt(cleanRut.charAt(body.length - i));
            if (multiple < 7) {
                multiple = multiple + 1;
            } else {
                multiple = 2;
            }
        }

        const dvAwait = 11 - (plus % 11);

        dv = dv == 'K' ? '10' : dv;
        dv = dv == (0).toString() ? '11' : dv;

        return dvAwait.toString() == dv;
    };

    const isValidRut = (rut: string) => {
        const clean = rut.replace(/[^0-9Kk]/g, '');
        return clean.length < 10;
    };

    const getDvRut = (rut: string) => {
        const cleanRut = unFormatRut(rut);
        const reverse = cleanRut.split('').reverse();
        const multiplier = [2, 3, 4, 5, 6, 7, 2, 3, 4];
        let plus = 0;

        reverse.map((dig: any, i) => {
            plus += dig * multiplier[i];
        });

        const op = parseInt((plus / 11 + '').split('.')[0]) * 11;
        const result = plus - op;
        switch (11 - result) {
            case 11:
                return '0';
            case 10:
                return 'K';
            default:
                return (11 - result).toString();
        }
    };

    return {
        formatRut,
        unFormatRut,
        validateRut,
        isValidRut,
        getDvRut
    };
};

export default useRut;
