import React from 'react';
import { DispatchProgramItemShowDataForm } from '../../../../app/models/DispatchProgramItem';

interface Props {
    dispatchProgramItemShowDataForm: DispatchProgramItemShowDataForm;
}

const DispatchProgramItemShow = ({ dispatchProgramItemShowDataForm }: Props) => {
    if (!dispatchProgramItemShowDataForm) return null;

    return (
        <>
            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Producto</label>
                <div className="col-md-9">
                    <input
                        type="text"
                        name="product_name"
                        className="form-control"
                        readOnly={true}
                        value={dispatchProgramItemShowDataForm.product_name}
                    />
                </div>
            </div>

            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Servicio</label>
                <div className="col-md-9">
                    <input
                        type="text"
                        name="service"
                        className="form-control"
                        readOnly={true}
                        value={dispatchProgramItemShowDataForm.service}
                    />
                </div>
            </div>

            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Cantidad</label>
                <div className="col-md-9">
                    <div className="row g-1">
                        <div className="col-6">
                            <input
                                type="number"
                                name="quantity"
                                className="form-control"
                                readOnly={true}
                                value={dispatchProgramItemShowDataForm.quantity}
                            />
                        </div>
                        <div className="col-6">
                            <input
                                type="text"
                                name="quantity_unit"
                                className="form-control"
                                readOnly={true}
                                value={dispatchProgramItemShowDataForm.quantity_unit}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Tarifa de venta</label>
                <div className="col-md-9 ">
                    <div className="row g-1">
                        <div className="col-6">
                            <input
                                type="number"
                                name="rate"
                                className="form-control"
                                readOnly={true}
                                value={dispatchProgramItemShowDataForm.rate}
                            />
                        </div>
                        <div className="col-6">
                            <input
                                type="text"
                                name="rate"
                                className="form-control"
                                readOnly={true}
                                value={dispatchProgramItemShowDataForm.currency}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Origen</label>
                <div className="col-md-9">
                    <input
                        type="text"
                        name="origin"
                        className="form-control"
                        readOnly={true}
                        value={dispatchProgramItemShowDataForm.origin}
                    />
                </div>
            </div>

            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Destino</label>
                <div className="col-md-9">
                    <input
                        type="text"
                        name="destiny"
                        className="form-control"
                        readOnly={true}
                        value={dispatchProgramItemShowDataForm.destiny}
                    />
                </div>
            </div>

            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Fecha de Despacho</label>
                <div className="col-md-9">
                    <input
                        type="datetime-local"
                        className="form-control"
                        name="dispatch_date"
                        id="dispatch_date"
                        value={
                            dispatchProgramItemShowDataForm.dispatch_date &&
                            dispatchProgramItemShowDataForm.dispatch_date.length > 20
                                ? dispatchProgramItemShowDataForm.dispatch_date.substring(
                                      0,
                                      dispatchProgramItemShowDataForm.dispatch_date.length - 6
                                  )
                                : dispatchProgramItemShowDataForm.dispatch_date
                        }
                        readOnly={true}
                    />
                    <div className="invalid-feedback" />
                </div>
            </div>

            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Descripción</label>
                <div className="col-md-9">
                    <textarea
                        className="form-control"
                        name="description"
                        id="description"
                        value={dispatchProgramItemShowDataForm.description}
                        readOnly={true}
                    />
                    <div className="invalid-feedback" />
                </div>
            </div>

            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">F. Creación</label>
                <div className="col-md-9">
                    <input
                        type="text"
                        name="created_at"
                        className="form-control"
                        readOnly={true}
                        value={
                            dispatchProgramItemShowDataForm.created_at
                                ? new Date(
                                      dispatchProgramItemShowDataForm.created_at
                                  ).toLocaleDateString()
                                : ''
                        }
                    />
                </div>
            </div>

            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">F. Modificación</label>
                <div className="col-md-9">
                    <input
                        type="text"
                        name="updated_at"
                        className="form-control"
                        readOnly={true}
                        value={
                            dispatchProgramItemShowDataForm.updated_at
                                ? new Date(
                                      dispatchProgramItemShowDataForm.updated_at
                                  ).toLocaleDateString()
                                : ''
                        }
                    />
                </div>
            </div>
        </>
    );
};

export default DispatchProgramItemShow;
