import React, { useEffect, useState } from 'react';
import { defaultTruck, Truck } from '../../../app/models/Truck';
import useTruckService from '../../../app/services/hooks/useTruckService';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import LazyLoading from '../../../components/LazyLoading';
import TruckFormContainer from './TruckFormContainer';

interface Props {
    truckId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: (message: string) => void;
}

const TruckEdit = ({ truckId, onSaved, onCancel, onError }: Props) => {
    const { fetchingEditTruck, editTruck, fetchingUpdateTruck, updateTruck } = useTruckService();

    const [truck, setTruck] = useState<Truck>(defaultTruck);
    const [errorFields, setErrorFields] = useState<any>();
    useEffect(() => {
        edit();
    }, []);

    const edit = () => {
        editTruck(truckId, {
            onSuccess: (response: ServiceResponse) => {
                const truckData = response.data.truck;
                setTruck(truckData);
            },
            onError: (response) => {
                if (onError) onError(response.message);
            }
        });
    };

    const update = () => {
        updateTruck(truckId, truck, {
            onSuccess: () => {
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (onError) onError(response.message);
            },
            onFieldError: (errorFields: ServiceResponse) => {
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingEditTruck ? (
        <LazyLoading height="300" />
    ) : (
        <TruckFormContainer
            fetching={fetchingUpdateTruck}
            action={update}
            cancel={cancel}
            truck={truck}
            setTruck={setTruck}
            errorFields={errorFields}
        />
    );
};

export default TruckEdit;
