import React, { useEffect } from 'react';
import { Place } from '../../../app/models/Place';
import useHandleErrorFields from '../../../hooks/useHandleErrorFields';

type Props = {
    place: Place;
    setPlace: (Place: Place) => void;
    errorFields?: any;
};

const PlaceForm = ({ place, setPlace, errorFields }: Props) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
    ) => {
        const { name, value } = e.target;
        setPlace({ ...place, [name]: value });
    };

    return (
        <>
            <div className="mb-2 row needs-validation">
                <label className="col-md-3 col-form-label">Nombre</label>
                <div className="col-md-9">
                    <input
                        name="name"
                        id="name"
                        type="text"
                        className={`form-control ${fieldHasError('name')}`}
                        value={place.name}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('name')}
                        placeholder="Ingrese el nombre del origen/destino"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('name')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row needs-validation">
                <label className="col-md-3 col-form-label">Latitud</label>
                <div className="col-md-9">
                    <input
                        name="latitude"
                        id="latitude"
                        type="number"
                        className={`form-control ${fieldHasError('latitude')}`}
                        value={place.latitude}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('latitude')}
                        placeholder="Ingrese latitud"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('latitude')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row needs-validation">
                <label className="col-md-3 col-form-label">Longitud</label>
                <div className="col-md-9">
                    <input
                        name="longitude"
                        id="longitude"
                        type="number"
                        className={`form-control ${fieldHasError('longitude')}`}
                        value={place.longitude}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('longitude')}
                        placeholder="Ingrese longitud"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('longitude')}
                    </div>
                </div>
            </div>
        </>
    );
};

export default PlaceForm;
