import React from 'react';
import { toast } from 'react-toastify';

type Props = {
    callbackSave: () => void;
    title?: string;
    loading?: boolean;
    locked?: boolean;
    message?: string;
};

const ButtonSaveForm = ({
    callbackSave,
    title = 'Guardar',
    locked = false,
    message = 'No se puede guardar este registro.'
}: Props) => {
    const showLockedMessage = () => {
        toast.error(message);
    };

    return !locked ? (
        <button
            type="button"
            className="waves-effect btn btn-primary btn-sm"
            onClick={locked ? showLockedMessage : callbackSave}
        >
            <i className="fa fa-save" /> {title}
        </button>
    ) : (
        <button type="button" className="waves-effect btn btn-primary btn-sm disabled">
            <i className="fa fa-save" /> {title}
        </button>
    );
};

export default ButtonSaveForm;
