import React, { useEffect } from 'react';
import { Hopper } from '../../../app/models/Hopper';
import useHandleErrorFields from '../../../hooks/useHandleErrorFields';
import InputButtonFile from '../../../components/inputs/InputButtonFile';

type Props = {
    hopper: Hopper;
    setHopper: (Hopper: Hopper) => void;
    errorFields?: any;
};

const HopperForm = ({ hopper, setHopper, errorFields }: Props) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
    ) => {
        const { name, value } = e.target;
        setHopper({ ...hopper, [name]: value });
    };

    const handleChangeDocumentFile = (e: FileList | null, type: string) => {
        const _hopper = hopper;
        const index = _hopper.documents?.findIndex((document) => document.type === type);
        if (e) {
            if (index !== undefined && index !== null && index >= 0 && _hopper.documents) {
                _hopper.documents[index].file = e[0];
            } else {
                _hopper.documents?.push({
                    type: type,
                    file: e[0]
                });
            }
            setHopper({
                ..._hopper
            });
        }
    };

    const handleChangeDocumentExpireDate = (
        e: React.ChangeEvent<HTMLInputElement>,
        type: string
    ) => {
        const _hopper = hopper;
        const index = _hopper.documents?.findIndex((document) => document.type === type);
        const { value } = e.target;

        if (index !== undefined && index !== null && index >= 0 && _hopper.documents) {
            _hopper.documents[index].expire_date = value;
        } else {
            _hopper.documents?.push({
                type: type,
                expire_date: value
            });
        }

        setHopper({
            ..._hopper
        });
    };

    return (
        <>
            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Patente</label>
                <div className="col-md-9">
                    <input
                        name="patent"
                        id="patent"
                        type="text"
                        className="form-control"
                        value={hopper.patent}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('patent')}
                        placeholder="Ingrese la patente"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('patent')}
                    </div>
                </div>
            </div>
            <hr />

            <div className="mb-2 row">
                <div className="col-md-6 col-3">
                    <label className="col-md-12 col-form-label">Permiso de circulacion</label>
                    <InputButtonFile
                        name={'PERMISO_DE_CIRCULACION'}
                        handleChangeDocumentFile={handleChangeDocumentFile}
                        objectFile={
                            hopper.documents?.find((item) => item.type === 'PERMISO_DE_CIRCULACION')
                                ?.file
                        }
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('PERMISO_DE_CIRCULACION')}
                    </div>
                </div>
                <div className="col-md-6 col-9 ">
                    <label className="col-md-12 col-form-label">Fecha de vencimiento permiso</label>
                    <input
                        name="PERMISO_DE_CIRCULACION_expire_date"
                        id="PERMISO_DE_CIRCULACION_expire_date"
                        type="date"
                        className={`form-control ${fieldHasError(
                            'PERMISO_DE_CIRCULACION_expire_date'
                        )}`}
                        value={
                            hopper.documents?.find((item) => item.type === 'PERMISO_DE_CIRCULACION')
                                ?.expire_date
                        }
                        onChange={(e) =>
                            handleChangeDocumentExpireDate(e, 'PERMISO_DE_CIRCULACION')
                        }
                        onFocus={() => onFocusRemove('PERMISO_DE_CIRCULACION_expire_date')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('PERMISO_DE_CIRCULACION_expire_date')}
                    </div>
                </div>
            </div>
            <div className="mb-2 row">
                <div className="col-md-6 col-3">
                    <label className="col-md-12 col-form-label">Revision tecnica</label>
                    <InputButtonFile
                        name={'REVISION_TECNICA'}
                        handleChangeDocumentFile={handleChangeDocumentFile}
                        objectFile={
                            hopper.documents?.find((item) => item.type === 'REVISION_TECNICA')?.file
                        }
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('REVISION_TECNICA')}
                    </div>
                </div>
                <div className="col-md-6 col-9 ">
                    <label className="col-md-12 col-form-label">
                        Fecha de vencimiento revision
                    </label>
                    <input
                        name="REVISION_TECNICA_expire_date"
                        id="REVISION_TECNICA_expire_date"
                        type="date"
                        className={`form-control ${fieldHasError('REVISION_TECNICA_expire_date')}`}
                        value={
                            hopper.documents?.find((item) => item.type === 'REVISION_TECNICA')
                                ?.expire_date
                        }
                        onChange={(e) => handleChangeDocumentExpireDate(e, 'REVISION_TECNICA')}
                        onFocus={() => onFocusRemove('REVISION_TECNICA_expire_date')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('REVISION_TECNICA_expire_date')}
                    </div>
                </div>
            </div>
            <div className="mb-2 row">
                <div className="col-md-6 col-3">
                    <label className="col-md-12 col-form-label">SOAP</label>
                    <InputButtonFile
                        name={'SOAP'}
                        handleChangeDocumentFile={handleChangeDocumentFile}
                        objectFile={hopper.documents?.find((item) => item.type === 'SOAP')?.file}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('SOAP')}
                    </div>
                </div>
                <div className="col-md-6 col-9 ">
                    <label className="col-md-12 col-form-label">Fecha de vencimiento SOAP</label>
                    <input
                        name="SOAP_expire_date"
                        id="SOAP_expire_date"
                        type="date"
                        className={`form-control ${fieldHasError('SOAP_expire_date')}`}
                        value={hopper.documents?.find((item) => item.type === 'SOAP')?.expire_date}
                        onChange={(e) => handleChangeDocumentExpireDate(e, 'SOAP')}
                        onFocus={() => onFocusRemove('SOAP_expire_date')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('SOAP_expire_date')}
                    </div>
                </div>
            </div>
        </>
    );
};

export default HopperForm;
