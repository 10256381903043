import { useContext, useState } from 'react';
import useFetch from '../../../hooks/useFetch';
import { EndPoints } from '../EndPoints';

import { ServiceEvents, ServiceEventsDocuments } from '../shared/interfaces';

import { InternalSettlementFilters } from '../../models/InternalSettlement';

const useInternalSettlementService = () => {
    const { doGet, doPost, doPut, doDelete, doGetDocument } = useFetch();

    const [fetchingGetInternalSettlements, setFetchingGetInternalSettlements] = useState(false);
    const [fetchingGetExcelInternalSettlements, setFetchingGetExcelInternalSettlements] =
        useState(false);
    const [fetchingShowInternalSettlement, setFetchingShowInternalSettlement] = useState(false);

    const getInternalSettlements = (
        filter: InternalSettlementFilters,
        events: ServiceEvents = {}
    ) => {
        const queryString = Object.entries(filter)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.INTERNAL_SETTLEMENTS.GET_INTERNAL_SETTLEMENTS}?${queryString}`;

        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetInternalSettlements
        });
    };

    const getExcelAllInternalSettlements = (
        internalSettlementFilters: InternalSettlementFilters,
        events: ServiceEventsDocuments = {}
    ) => {
        // const {showLoading, hideLoading, changeAnimation} = useContext(AppContext);
        const queryString = Object.entries(internalSettlementFilters)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.INTERNAL_SETTLEMENTS.GET_EXCEL_INTERNAL_SETTLEMENTS}?${queryString}`;

        doGetDocument({
            ...events,
            url: url,
            successData: {
                nameDocument: 'liquidación_interna.xlsx',
                message: 'Descarga exitosa.'
            },
            setFetching: setFetchingGetExcelInternalSettlements
        });
    };

    const showInternalSettlement = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.INTERNAL_SETTLEMENTS.SHOW_INTERNAL_SETTLEMENT.replace(
                ':id',
                id.toString()
            ),
            setFetching: setFetchingShowInternalSettlement
        });
    };

    return {
        fetchingGetInternalSettlements,
        fetchingGetExcelInternalSettlements,
        fetchingShowInternalSettlement,
        getInternalSettlements,
        getExcelAllInternalSettlements,
        showInternalSettlement
    };
};

export default useInternalSettlementService;
