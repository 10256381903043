import React, { useEffect, useState } from 'react';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import SelectTwo, { SelectTwoMapperOptions } from '../../../../components/SelectTwo';
import { formatAmount, formatMoney, setCleanTextAreaError } from '../../../../utils/utils';
import { Tax } from '../../../../app/models/Tax';

import { Items, ServiceType } from '../../../../app/shared/enums';
import { ServiceManifestDetailDataForm } from '../../../../app/models/ServiceManifestDetail';
import { momentParseDateTime } from '../../../../helpers';

interface Props {
    serviceManifestDetailDataForm: ServiceManifestDetailDataForm;
    setserviceManifestDetailDataForm: (
        serviceManifestDetailDataForm: ServiceManifestDetailDataForm
    ) => void;
    itemType: string;
}

const ServiceManifestDetailFormShow = ({ serviceManifestDetailDataForm, itemType }: Props) => {
    if (!serviceManifestDetailDataForm) return null;

    return (
        <>
            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">
                        Posición orden de compra #{serviceManifestDetailDataForm.purchase_order_id}
                    </label>
                    <input
                        className="form-control"
                        type="text"
                        name="purchase_order_detail"
                        id="purchase_order_detail"
                        value={serviceManifestDetailDataForm.purchase_order_detail}
                        disabled={true}
                    />
                </div>
            </div>

            {itemType == Items.PRODUCT ? (
                <>
                    <div className="mb-2 row">
                        <div className="col-md-12">
                            <label className="col-form-label">Producto</label>
                            <input
                                className="form-control"
                                type="text"
                                name="item_name"
                                id="item_name"
                                value={serviceManifestDetailDataForm.item_name}
                                disabled={true}
                            />
                        </div>
                    </div>
                </>
            ) : (
                <div className="mb-2 row">
                    <div className="col-md-12">
                        <label className="col-form-label">Servicio</label>
                        <input
                            className="form-control"
                            type="text"
                            name="item_name"
                            id="item_name"
                            value={serviceManifestDetailDataForm.item_name}
                            disabled={true}
                        />
                    </div>
                </div>
            )}

            <div className="mb-2 row">
                <label className="col-md-12 col-form-label">Precio</label>
                <div className="col-md-12">
                    <div className="row g-1">
                        <div className="col-6">
                            <input
                                className="form-control"
                                type="text"
                                name="price"
                                id="price"
                                value={serviceManifestDetailDataForm.price}
                                disabled={true}
                            />
                        </div>

                        <div className="col-3">
                            <input
                                className="form-control"
                                type="text"
                                name="currency_price"
                                id="currency_price"
                                value={serviceManifestDetailDataForm.currency_price}
                                disabled={true}
                            />
                        </div>

                        <div className="col-3">
                            <input
                                className="form-control"
                                type="text"
                                name="unit_price"
                                id="unit_price"
                                value={serviceManifestDetailDataForm.unit_price}
                                disabled={true}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <label className="col-md-12 col-form-label">Cantidad</label>
                <div className="col-md-12">
                    <div className="row g-1">
                        <div className="col-6">
                            <input
                                className="form-control"
                                type="number"
                                name="quantity"
                                id="quantity"
                                value={serviceManifestDetailDataForm.quantity}
                                disabled={true}
                            />
                        </div>

                        <div className="col-6">
                            <input
                                className="form-control"
                                type="text"
                                name="unit"
                                id="unit"
                                value={serviceManifestDetailDataForm.unit}
                                disabled={true}
                            />
                        </div>
                    </div>
                </div>
            </div>
    
            <div className="mb-2 row">
                <div className="col-md-12">
                    <div>
                        <label className="col-form-label">Impuestos</label>

                        {serviceManifestDetailDataForm.json_tax ? (
                            <div className="fs-4">
                                {serviceManifestDetailDataForm.json_tax.map((tax: Tax) => (
                                    <span key={tax.id} className="badge bg-info mx-1">
                                        {formatMoney(
                                            Number(serviceManifestDetailDataForm.price) *
                                                Number(tax.value)
                                        )}{' '}
                                        ({tax.name})
                                    </span>
                                ))}
                            </div>
                        ) : (
                            <div className="fs-6">No tiene impuestos asignados.</div>
                        )}
                    </div>
                </div>
            </div>
            {
                serviceManifestDetailDataForm.service_type === ServiceType.TRANSPORT &&

                <div className='mt-3'>
                    <hr/>
                    <h5 className="mb-3">Datos Despacho</h5>
                   
                        <div className="mb-2 row">
                            <label className="col-md-3 col-form-label">Cantidad plan</label>
                            <div className="col-md-9">
                                <input
                                    className="form-control"
                                    type="text"
                                    name="plan_quantity"
                                    id="plan_quantity"
                                    value={serviceManifestDetailDataForm.plan_quantity?formatAmount(serviceManifestDetailDataForm.plan_quantity) :''}
                                    readOnly={true}
                                />
                            </div>
                        </div>
                        <div className="mb-2 row">
                            <label className="col-md-3 col-form-label">Fecha despacho</label>
                            <div className="col-md-9">
                                <input
                                    className="form-control"
                                    type="datetime-local"
                                    name="dispatch_date"
                                    id="dispatch_date"
                                    value={
                                        (serviceManifestDetailDataForm.dispatch_date && serviceManifestDetailDataForm.dispatch_date.length > 20)
                                            ? serviceManifestDetailDataForm.dispatch_date.substring(
                                                  0,
                                                  serviceManifestDetailDataForm.dispatch_date.length - 6
                                              )
                                            : serviceManifestDetailDataForm.dispatch_date
                                    }
                                    readOnly={true}
                                />
                        </div>
                        </div>
                  
                    <h5 className="mb-3">Datos transporte</h5>
                    <div className="mb-2 row">
                        <label className="col-md-3 col-form-label">Proveedor</label>
                        <div className="col-md-9">
                            <input
                                className="form-control"
                                type="text"
                                name="provider"
                                id="provider"
                                value={serviceManifestDetailDataForm.provider??''}
                                readOnly={true}
                            />
                        </div>
                    </div>
                    <div className="mb-2 row">
                        <label className="col-md-3 col-form-label">Conductor</label>
                        <div className="col-md-9">
                            <input
                                className="form-control"
                                type="text"
                                name="carrier"
                                id="carrier"
                                value={serviceManifestDetailDataForm.carrier??''}
                                readOnly={true}
                            />
                        </div>
                    </div>
                    <div className="mb-2 row">
                        <label className="col-md-3 col-form-label">Tracto</label>
                        <div className="col-md-9">
                            <input
                                className="form-control"
                                type="text"
                                name="truck"
                                id="truck"
                                value={serviceManifestDetailDataForm.truck??''}
                                readOnly={true}
                            />
                        </div>
                    </div>
                    <div className="mb-2 row">
                        <label className="col-md-3 col-form-label">Tolva</label>
                        <div className="col-md-9">
                            <input
                                className="form-control"
                                type="text"
                                name="hopper"
                                id="hopper"
                                value={serviceManifestDetailDataForm.hopper??''}
                                readOnly={true}
                            />
                        </div>
                    </div>
                    <h5 className="mb-3">Datos Guia</h5>
                    <div className="mb-2 row">
                        <label className="col-md-3 col-form-label">Archivo</label>
                        <div className="col-md-9 d-flex align-items-center">
                            {serviceManifestDetailDataForm.file_url ? (
                                <div>
                                    <a
                                        href={serviceManifestDetailDataForm.file_url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="btn btn-success btn-sm"
                                        role="button"
                                        aria-pressed="true"
                                    >
                                        Guia
                                    </a>
                                </div>
                            ) : (
                                <input
                                    className="form-control"
                                    type="text"
                                    value='No tiene'
                                    readOnly={true}
                                />
                            )}
                        </div>
                    </div>
                    <div className="mb-2 row">
                    <label className="col-md-3 col-form-label">Folio Guia</label>
                    <div className="col-md-9">
                        <input
                            className="form-control"
                            type="text"
                            name="folio"
                            id="folio"
                            value={serviceManifestDetailDataForm.folio?? ''}
                            readOnly={true}
                        />
                    </div>
                    </div>
                    <div className="mb-2 row">
                    <label className="col-md-3 col-form-label">Neto Guia</label>
                    <div className="col-md-9">
                        <input
                            className="form-control"
                            type="text"
                            name="total"
                            id="total"
                            value={serviceManifestDetailDataForm.total? formatAmount(serviceManifestDetailDataForm.total) : ''}
                            readOnly={true}
                        />
                    </div>
                    </div>
                    <div className="mb-2 row">
                    <label className="col-md-3 col-form-label">Tara Guia</label>
                    <div className="col-md-9">
                        <input
                            className="form-control"
                            type="text"
                            name="tara_total"
                            id="tara_total"
                            value={serviceManifestDetailDataForm.tara_total?formatAmount(serviceManifestDetailDataForm.tara_total) :''}
                            readOnly={true}
                        />
                    </div>
                    </div>
                    <div className="mb-2 row">
                    <label className="col-md-3 col-form-label">Fecha Real Despacho</label>
                    <div className="col-md-9">
                        <input
                            className="form-control"
                            type="date"
                            name="actual_dispatch_date"
                            id="actual_dispatch_date"
                            value={serviceManifestDetailDataForm.actual_dispatch_date}
                            readOnly={true}
                        />
                    </div>
                    </div>  
                </div>
            }

                
        </>
    );
};

export default ServiceManifestDetailFormShow;
