import React from 'react';
import { Modal } from 'react-bootstrap';
import ButtonCancelForm from '../buttons/ButtonCancelForm';

type ModalProps = {
    children: JSX.Element | JSX.Element[];
    title: string;
    show: boolean;
    showCancel?: boolean;
    showFooter?: boolean;
    size?: 'sm' | 'lg';
    handleClose: any;
    actionButton?: any;
    centered?: boolean;
    backdrop?: boolean;
    onExited?: any;
};

const DefaultModal = ({
    children,
    title,
    show,
    showCancel = true,
    showFooter = true,
    size,
    handleClose,
    actionButton,
    centered,
    backdrop,
    onExited = null
}: ModalProps) => {
    return (
        <Modal
            centered={centered}
            backdrop={backdrop ? backdrop : 'static'}
            show={show}
            size={size}
            onHide={handleClose}
            onExited={onExited}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <h4 className="card-title text-uppercase mb-0">{title}</h4>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>{children}</Modal.Body>
            {showFooter && (
                <Modal.Footer>
                    {showCancel && <ButtonCancelForm callbackCancel={handleClose} />}

                    {actionButton}
                </Modal.Footer>
            )}
        </Modal>
    );
};

export default DefaultModal;
