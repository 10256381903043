// import React from 'react';
import React from 'react'
import SocietyForm from './forms/SocietyForm';
import { SocietyData, SocietyDataForm } from '../../../app/models/Society';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { User } from '../../../app/models/User';
import { Client } from '../../../app/models/Client';
import { Provider } from '../../../app/models/Provider';


interface Props {
    fetching?: boolean;
    action: () => void;
    cancel: () => void;
    societyForm: SocietyDataForm;
    setSocietyForm: (society: SocietyDataForm) => void;
    clients: Client[];
    providers: Provider[];
    errorFields?: any;
}

const SocietyFormContainer = ({
    fetching,
    action,
    cancel,
    societyForm,
    setSocietyForm,
    clients,
    providers,
    errorFields = null,
}: Props) => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <SocietyForm
                        societyForm={societyForm}
                        setSocietyForm={setSocietyForm}
                        clients={clients}
                        providers={providers}
                        errorFields={errorFields}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetching} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={action} locked={fetching} />
                </div>
            </div>
        </>
    );
};

export default SocietyFormContainer;
