import React from 'react';
import TruckForm from './TruckForm';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { Truck } from '../../../app/models/Truck';

interface Props {
    fetching?: boolean;
    action: () => void;
    cancel: () => void;
    truck: Truck;
    setTruck: (Truck: Truck) => void;
    errorFields?: any;
}

const TruckFormContainer = ({
    fetching,
    action,
    cancel,
    truck,
    setTruck,
    errorFields = null
}: Props) => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <TruckForm truck={truck} setTruck={setTruck} errorFields={errorFields} />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetching} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={action} locked={fetching} />
                </div>
            </div>
        </>
    );
};

export default TruckFormContainer;
