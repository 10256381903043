import React, { useEffect, useState } from 'react';
import { Client, defaultClient } from '../../../app/models/Client';
import useClientService from '../../../app/services/hooks/useClientService';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import LazyLoading from '../../../components/LazyLoading';
import ClientFormContainer from './ClientFormContainer';
import useSweetAlert from '../../../hooks/useSweetAlert';

interface Props {
    clientId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const ClientEdit = ({ clientId, onSaved, onCancel, onError }: Props) => {
    const { fetchingEditClient, editClient, fetchingUpdateClient, updateClient } =
        useClientService();

    const [client, setClient] = useState<Client>(defaultClient);
    const [errorFields, setErrorFields] = useState<any>();

    useEffect(() => {
        edit();
    }, []);

    const edit = () => {
        editClient(clientId, {
            onSuccess: (response: ServiceResponse) => {
                setClient(response.data.client);
            },
            onError: () => {
                if (onError) onError();
            }
        });
    };

    const update = () => {
        useSweetAlert().spinnerAlert('Actualizando cliente...');
        updateClient(clientId, client, {
            onSuccess: () => {
                useSweetAlert().closeSpinnerAlert();
                if (onSaved) onSaved();
            },
            onError: () => {
                useSweetAlert().closeSpinnerAlert();
                if (onError) onError();
            },
            onFieldError: (errorFields: ServiceResponse) => {
                useSweetAlert().closeSpinnerAlert();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingEditClient ? (
        <LazyLoading height="300" />
    ) : (
        <ClientFormContainer
            fetching={fetchingUpdateClient}
            action={update}
            cancel={cancel}
            client={client}
            setClient={setClient}
            errorFields={errorFields}
        />
    );
};

export default ClientEdit;
