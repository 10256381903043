import useFetch from '../../../hooks/useFetch';
import { useState } from 'react';
import { DailyCarrier, DailyCarrierFilters } from '../../models/DailyCarrier';
import { ServiceEvents } from '../shared/interfaces';
import { EndPoints } from '../EndPoints';

const useDailyCarrierService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [fetchingGetDailyCarriers, setFetchingGetDailyCarriers] = useState(false);
    const [fetchingShowDailyCarrier, setFetchingShowDailyCarrier] = useState(false);
    const [fetchingCreateDailyCarrier, setFetchingCreateDailyCarrier] = useState(false);
    const [fetchingStoreDailyCarrier, setFetchingStoreDailyCarrier] = useState(false);
    const [fetchingEditDailyCarrier, setFetchingEditDailyCarrier] = useState(false);
    const [fetchingUpdateDailyCarrier, setFetchingUpdateDailyCarrier] = useState(false);
    const [fetchingDeleteDailyCarrier, setFetchingDeleteDailyCarrier] = useState(false);
    const [fetchingGetDailyCarriersWithServiceManifest, setFetchingGetDailyCarriersWithServiceManifest] = useState(false);
    
    const getDailyCarriers = (
        dailyCarrierFilters: DailyCarrierFilters,
        events: ServiceEvents = {}
    ) => {
        let url = new URL(EndPoints.DAILY_CARRIERS.GET_DAILY_CARRIERS);
        url = getUrlDailyCarrierFilters(
            url,
            dailyCarrierFilters.page,
            dailyCarrierFilters.per_page,
            dailyCarrierFilters.sort,
            dailyCarrierFilters.order
            // dailyCarrierFilters.daily_carrier_id,
            // dailyCarrierFilters.provider,
            // dailyCarrierFilters.carrier,
            // dailyCarrierFilters.truck,
            // dailyCarrierFilters.hopper
        );
        doGet({
            ...events,
            url: url.href,
            setFetching: setFetchingGetDailyCarriers
        });
    };

    const showDailyCarrier = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.DAILY_CARRIERS.GET_DAILY_CARRIER.replace(':id', id.toString()),
            setFetching: setFetchingShowDailyCarrier
        });
    };

    const createDailyCarrier = (events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.DAILY_CARRIERS.CREATE_DAILY_CARRIER,
            setFetching: setFetchingCreateDailyCarrier
        });
    };

    const storeDailyCarrier = (dailyCarrier: DailyCarrier, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.DAILY_CARRIERS.STORE_DAILY_CARRIER,
            body: dailyCarrier,
            setFetching: setFetchingStoreDailyCarrier
        });
    };

    const editDailyCarrier = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.DAILY_CARRIERS.EDIT_DAILY_CARRIER.replace(':id', id.toString()),
            setFetching: setFetchingEditDailyCarrier
        });
    };

    const updateDailyCarrier = (
        id: number,
        dailyCarrier: DailyCarrier,
        events: ServiceEvents = {}
    ) => {
        doPut({
            ...events,
            url: EndPoints.DAILY_CARRIERS.UPDATE_DAILY_CARRIER.replace(':id', id.toString()),
            body: dailyCarrier,
            setFetching: setFetchingUpdateDailyCarrier
        });
    };

    const deleteDailyCarrier = (id: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.DAILY_CARRIERS.DELETE_DAILY_CARRIER.replace(':id', id.toString()),
            setFetching: setFetchingDeleteDailyCarrier
        });
    };

    const getUrlDailyCarrierFilters = (
        url: URL,
        page: number,
        per_page: number,
        sort: string,
        order: string
        // daily_carrier_id: number | null,
        // provider: string | null,
        // carrier: string | null,
        // truck: string | null,
        // hopper: string | null
    ) => {
        if (page) {
            url.searchParams.append('page', String(page));
        }
        if (per_page) {
            url.searchParams.append('per_page', String(per_page));
        }
        if (sort) {
            url.searchParams.append('sort', String(sort));
        }
        if (order) {
            url.searchParams.append('order', String(order));
        }

        // if (daily_carrier_id) {
        //     url.searchParams.append('id', String(daily_carrier_id));
        // }
        // if (provider) {
        //     url.searchParams.append('provider', String(provider));
        // }
        // if (carrier) {
        //     url.searchParams.append('carrier', String(carrier));
        // }
        // if (truck) {
        //     url.searchParams.append('truck', String(truck));
        // }
        // if (hopper) {
        //     url.searchParams.append('hopper', String(hopper));
        // }

        return url;
    };

    const getDailyCarriersWithServiceManifest = (
        dailyCarrierFilters: DailyCarrierFilters,
        dispatch_program_id: number,
        events: ServiceEvents = {}
    ) => {
        const queryString = Object.entries(dailyCarrierFilters)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.DAILY_CARRIERS.GET_DAILY_CARRIERS_WITH_SERVICE_MANIFESTS}?${queryString}`;
        console.log(url.replace(':dispatch_program_id', dispatch_program_id.toString()))
        doGet({
            ...events,
            url: url.replace(':dispatch_program_id', dispatch_program_id.toString()),
            setFetching: setFetchingGetDailyCarriersWithServiceManifest
        });
    };

    

    return {
        fetchingGetDailyCarriers,
        fetchingShowDailyCarrier,
        fetchingCreateDailyCarrier,
        fetchingStoreDailyCarrier,
        fetchingEditDailyCarrier,
        fetchingUpdateDailyCarrier,
        fetchingDeleteDailyCarrier,
        fetchingGetDailyCarriersWithServiceManifest,
        getDailyCarriers,
        showDailyCarrier,
        createDailyCarrier,
        storeDailyCarrier,
        editDailyCarrier,
        updateDailyCarrier,
        deleteDailyCarrier,
        getDailyCarriersWithServiceManifest
    };
};

export default useDailyCarrierService;
