import React from 'react';

type ToolBarProps = {
    left?: any;
    center?: any;
    right?: any;
};

const DefaultToolBar = ({ left, center = null, right }: ToolBarProps) => {
    return (
        <div className="row mb-3">
            {left ? <div className="col-auto text-left">{left} </div> : null}
            {center ? <div className="col text-center">{center} </div> : null}
            {right ? <div className="col text-right">{right}</div> : null}
        </div>
    );
};

export default DefaultToolBar;
