import moment from 'moment';

export const formatDateToInputDate = (date: Date | string) => {
    if (typeof date === 'string') {
        return date;
    }
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    return date.getFullYear() + '-' + month + '-' + day;
};

export const momentParseDate = (date: string | any, format = 'DD-MM-YYYY') => {
    if (!date) {
        return '';
    }
    return moment(date).format(format);
};

export const momentParseDateTime = (date: string | any) => {
    if (!date) {
        return '';
    }
    return moment(date).format('DD-MM-YYYY HH:MM');
};
