export const APP_CONFIG = {
    APP_NAME: '',
    APP_TITLE: ''
};
export const LOCAL_STORAGE = {
    AUTH: '_TICSA_ORIGEN_PWA_AT_ec82cccc1563b15bda8748b13eba5576',
    AUTH_TOKEN: '_TICSA_ORIGEN_PWA_ATK_667e297dbf1a3b67f0adc256ad07e041'
};

export interface StyleCondition {

    key: string;
    properties: {
        option: {
            color: {
                normal: string,
                hover: string
            },
            background: {
                normal: string,
                hover: string,
               
            },
            fontWeight:{
                normal: string,
                hover: string,
            }
        }
    }
    
}

export const select2Config = (hasError = false) => ({
    control: (base: any) => ({
        ...base,
        // height: 33,
        minHeight: 35,
        borderRadius: '3px',
        border: hasError ? '1px solid red' : '1px solid #ced4da'
    }),
    valueContainer: (base: any) => ({
        ...base,
        minHeight: 35
        // marginTop : '-2px'
    }),
    dropdownIndicator: (base: any) => ({
        ...base,
        minHeight: 35,
        padding: '5px'
        // marginTop : '-2px'
    }),
    option: (provided:any, state:any) => {

        // const isManifest = state.data.has_service_manifest; // Accede al campo has_service_manifest
        let optionStyle = undefined;

        if(state.data.condition){
           
            if (Object.prototype.hasOwnProperty.call(state.data, state.data.condition.key)) {

                optionStyle = {
                    value: state.data[state.data.condition.key],
                    ...state.data.condition
                };
                
            }
       
        }
        return {
            ...provided,
            color: state.isSelected
                ? 'white'
                : (optionStyle && optionStyle?.value)
                    ? optionStyle.properties?.option?.color.normal
                    : '', 
            background: (optionStyle && optionStyle?.value) ? optionStyle.properties?.option?.background.normal: '',
            fontWeight: (optionStyle && optionStyle?.value) ? optionStyle.properties?.option?.fontWeight.normal: '',
            '&:hover': {
                color: (optionStyle && optionStyle?.value) ? optionStyle.properties?.option?.color.hover : '', 
                background: (optionStyle && optionStyle?.value) ? optionStyle.properties?.option?.background.hover: ''
            },
        };
    },
    multiValue: (base: any) => ({
        ...base,
        background: '#1b7ee0',
        color: 'white'
    }),
    multiValueLabel: (base: any) => ({
        ...base,
        background: '#1b7ee0',
        color: 'white'
    })
});

export const defaultSwalStyleButtons = {
    customClass: {
        confirmButton: 'waves-effect btn btn-sm btn-success px-3',
        cancelButton: 'waves-effect btn btn-outline-light btn-sm px-3'
    },
    buttonsStyling: false
};

export const defaultSwalStyleTwoButtons = {
    customClass: {
        confirmButton: 'waves-effect btn btn-sm btn-success px-3',
        denyButton: 'waves-effect btn btn-sm btn-warning px-3',
        cancelButton: 'waves-effect btn btn-outline-light btn-sm px-3'
    },
    buttonsStyling: false
};
