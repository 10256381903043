import React, { useEffect, useState } from 'react';
import DefaultToolBar from '../../../components/default/DefaultToolBar';
import ButtonCreate from '../../../components/buttons/ButtonCreate';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import DefaultCard from '../../../components/default/DefaultCard';
import DefaultModal from '../../../components/default/DefaultModal';
import { toast } from 'react-toastify';
import {
    TransportationOrderItemsFilters,
    TransportationOrderItemDataForm
} from '../../../app/models/TransportationOrderItem';
import useTransportationOrderItemService from '../../../app/services/hooks/useTransportationOrderItemService';
import TransportationOrderItemCreate from './TransportationOrderItemCreate';
import TransportationOrderItemEdit from './TransportationOrderItemEdit';
import useSweetAlert from '../../../hooks/useSweetAlert';
import TransportationOrderItemShow from './TransportationOrderItemShow';
import TransportationOrderItemSplit from './TransportationOrderItemSplit';
import LazyLoading from '../../../components/LazyLoading';
import { TransportationOrderStatus } from '../../../app/models/TransportationOrderStatus';
import { TransportationOrderDataForm } from '../../../app/models/TransportationOrder';
import TransportationOrderItemEditRates from './TransportationOrderItemEditRates';
import TransportationOrderItemsDataTable from './tables/TranportationOrderItemsDataTable';

interface Props {
    transportationOrderId: number;
    canBeEdited: boolean;
    isShow?: boolean;
    currencyId: number | string;
    transportationOrderForm: TransportationOrderDataForm;
    setTransportationOrderForm?: (transportationOrderForm: TransportationOrderDataForm) => void;
    reloadModule?: () => void;
}

const TransportationOrderItemsContainer = ({
    transportationOrderId,
    canBeEdited,
    currencyId,
    transportationOrderForm,
    setTransportationOrderForm,
    reloadModule,
    isShow = false
}: Props) => {
    const {
        fetchingGetTransportationOrderItemsByOrder,
        getAllTransportationOrderItemsByOrder,
        deleteTransportationOrderItem
    } = useTransportationOrderItemService();

    const [showingEdit, setShowingEdit] = useState(false);
    const [showingShow, setShowingShow] = useState(false);
    const [showingCreate, setShowingCreate] = useState(false);
    const [showingSplit, setShowingSplit] = useState(false);
    const [showingEditRates, setShowingEditRates] = useState(false);
    const [statuses, setStatuses] = useState<TransportationOrderStatus[]>([]);
    const [totalRows, setTotalRows] = useState<number>(0);

    const [transportationOrderItemIdEdit, setTransportationOrderItemIdEdit] = useState<number>(-1);
    const [transportationOrderItemIdEditRates, setTransportationOrderItemIdEditRates] =
        useState<number>(-1);
    const [transportationOrderItemIdShow, setTransportationOrderItemIdShow] = useState<number>(-1);
    const [transportationOrderItemIdSplit, setTransportationOrderItemIdSplit] =
        useState<number>(-1);

    const [transportationOrderItems, setTransportationOrderItems] = useState<
        TransportationOrderItemDataForm[]
    >([]);

    const [filter, setFilter] = useState<TransportationOrderItemsFilters>({
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'desc'
    });

    useEffect(() => {
        if (transportationOrderId) {
            reloadTable();
        }
    }, [transportationOrderId]);

    useEffect(() => {
        reloadTable();
    }, [filter]);

    const showCreate = () => {
        setShowingCreate(true);
    };

    const hideCreate = () => {
        setShowingCreate(false);
    };

    const showEdit = (transportationOrderItemId: number) => {
        setShowingEdit(true);
        setTransportationOrderItemIdEdit(transportationOrderItemId);
    };

    const hideEdit = () => {
        setShowingEdit(false);
        setTransportationOrderItemIdEdit(-1);
    };

    const showShow = (transportationOrderItemId: number) => {
        setShowingShow(true);
        setTransportationOrderItemIdShow(transportationOrderItemId);
    };

    const showEditRates = (transportationOrderItemId: number) => {
        setShowingEditRates(true);
        setTransportationOrderItemIdEditRates(transportationOrderItemId);
    };

    const hideEditRates = () => {
        setShowingEditRates(false);
        setTransportationOrderItemIdEditRates(-1);
    };

    const hideShow = () => {
        setShowingShow(false);
        setTransportationOrderItemIdShow(-1);
    };

    const showSplit = (transportationOrderItemId: number) => {
        setShowingSplit(true);
        setTransportationOrderItemIdSplit(transportationOrderItemId);
    };

    const hideSplit = () => {
        setShowingSplit(false);
        setTransportationOrderItemIdSplit(-1);
    };

    const destroy = (transportationOrderItemId: number) => {
        const _text =
            'Está a punto de eliminar el item de pedido de transporte #' +
            transportationOrderItemId;

        useSweetAlert().requestConfirmation({
            title: '¿Está seguro?',
            text: _text,
            confirmButtonText: 'Si, eliminar',
            cancelButtonText: 'Cancelar',
            onConfirm: () => {
                useSweetAlert().spinnerAlert('Eliminando detalle pedido...', 400);
                deleteTransportationOrderItem(transportationOrderItemId, {
                    onSuccess: (response: ServiceResponse) => {
                        useSweetAlert().successAlert({
                            title: 'Éxito',
                            text: response.message
                        });
                        reloadTable();
                        if (reloadModule) reloadModule();
                    },
                    onError: (response: ServiceResponse) => {
                        useSweetAlert().errorAlert({
                            title: 'Error',
                            text: response.message
                        });
                    }
                });
            },
            onCancel: () => {
                useSweetAlert().infoAlert({
                    title: 'Cancelado',
                    text: 'El item de pedido de transporte no se ha eliminado.'
                });
            }
        });
    };

    const reloadTable = () => {
        getAllTransportationOrderItemsByOrder(filter, transportationOrderId, {
            onSuccess: (response: ServiceResponse) => {
                setTransportationOrderItems(response.data.transportation_order_items);
                setStatuses(response.data.statuses ?? []);
            }
        });
    };

    return (
        <>
            <DefaultCard>
                <div className="row">
                    <div className="col-12">
                        {!isShow ? (
                            <DefaultToolBar left={<ButtonCreate callbackCreate={showCreate} />} />
                        ) : null}
                    </div>
                    <div className="col-12">
                        {isShow ? (
                            <>
                                <TransportationOrderItemsDataTable
                                    transportationOrderItems={transportationOrderItems}
                                    totalRows={totalRows}
                                    statuses={statuses}
                                    loading={fetchingGetTransportationOrderItemsByOrder}
                                    filter={filter}
                                    setFilter={setFilter}
                                    show={showShow}
                                    changeStatus={false}
                                    canBeEdited={!isShow}
                                />
                            </>
                        ) : (
                            <>
                                <TransportationOrderItemsDataTable
                                    transportationOrderItems={transportationOrderItems}
                                    totalRows={totalRows}
                                    statuses={statuses}
                                    loading={fetchingGetTransportationOrderItemsByOrder}
                                    destroy={destroy}
                                    edit={showEdit}
                                    filter={filter}
                                    setFilter={setFilter}
                                    show={showShow}
                                    changeStatus={false}
                                    canBeEdited={!isShow}
                                />
                            </>
                        )}
                    </div>
                </div>
            </DefaultCard>
            {showingCreate ? (
                <DefaultModal
                    show={showingCreate}
                    handleClose={hideCreate}
                    title="Crear Detalle Pedido"
                    backdrop={true}
                    showFooter={false}
                >
                    <TransportationOrderItemCreate
                        canBeEdited={canBeEdited}
                        transportationOrderId={transportationOrderId}
                        onSaved={() => {
                            reloadTable();
                            if (reloadModule) reloadModule();
                            hideCreate();
                        }}
                        onError={() => {
                            hideCreate();
                        }}
                        onCancel={hideCreate}
                        currencyId={currencyId}
                    />
                </DefaultModal>
            ) : null}

            {showingEdit ? (
                <DefaultModal
                    show={showingEdit}
                    handleClose={hideEdit}
                    title="Editar Detalle Pedido"
                    backdrop={true}
                    showFooter={false}
                >
                    <TransportationOrderItemEdit
                        canBeEdited={canBeEdited}
                        transportationOrderItemId={transportationOrderItemIdEdit}
                        transportationOrderId={transportationOrderId}
                        onSaved={() => {
                            reloadTable();
                            if (reloadModule) reloadModule();
                            hideEdit();
                        }}
                        onCancel={hideEdit}
                        onError={() => {
                            hideEdit();
                        }}
                    />
                </DefaultModal>
            ) : null}
            {showingEditRates ? (
                <DefaultModal
                    show={showingEditRates}
                    handleClose={hideEditRates}
                    title="Editar Tarifas"
                    backdrop={true}
                    showFooter={false}
                >
                    <TransportationOrderItemEditRates
                        canBeEdited={canBeEdited}
                        transportationOrderItemId={transportationOrderItemIdEditRates}
                        transportationOrderForm={transportationOrderForm}
                        setTransportationOrderForm={setTransportationOrderForm}
                        onSaved={() => {
                            reloadTable();
                            hideEditRates();
                        }}
                        onCancel={hideEditRates}
                        onError={(message) => {
                            toast.error(message, {
                                autoClose: 2000 //
                            });
                            hideEditRates();
                        }}
                    />
                </DefaultModal>
            ) : null}
            {showingShow ? (
                <DefaultModal
                    show={showingShow}
                    handleClose={hideShow}
                    title="Ver detalle del item"
                    backdrop={true}
                    showFooter={false}
                >
                    <TransportationOrderItemShow
                        transportationOrderItemId={transportationOrderItemIdShow}
                        onError={(message?: string) => {
                            hideShow();
                            toast.error(message ?? 'Error al obtener el item de transporte');
                        }}
                    />
                </DefaultModal>
            ) : null}
            {showingSplit ? (
                <DefaultModal
                    show={showingSplit}
                    handleClose={hideSplit}
                    title="DIVIDIR DETALLE DEL PEDIDO DE TRANSPORTE"
                    backdrop={true}
                    showFooter={false}
                >
                    <TransportationOrderItemSplit
                        canBeEdited={true}
                        transportationOrderItemId={transportationOrderItemIdSplit}
                        onSaved={() => {
                            reloadTable();
                            hideSplit();
                        }}
                        onCancel={hideSplit}
                    />
                </DefaultModal>
            ) : null}
        </>
    );
};

export default TransportationOrderItemsContainer;
