import React from 'react';
import { momentParseDate } from '../../helpers';

interface UserBudgetFormProps {
    createdBy?: string;
    createdAt?: string;
    updatedBy?: string;
    updatedAt?: string;
    status?: string;
}

const DefaultCreatedModifiedUserForm = ({
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
    status
}: UserBudgetFormProps) => {
    if (!createdBy) return null;

    return (
        <>
            {createdBy && (
                <div className="mb-2 row">
                    <label className="col-md-3 col-form-label">Creado Por</label>
                    <div className="col-md-9">
                        <input
                            type="text"
                            className="form-control"
                            readOnly={true}
                            value={createdBy}
                        />
                    </div>
                </div>
            )}

            {createdAt && (
                <div className="mb-2 row">
                    <label className="col-md-3 col-form-label">Fecha Creado</label>
                    <div className="col-md-9">
                        <input
                            type="text"
                            className="form-control"
                            readOnly={true}
                            value={momentParseDate(createdAt)}
                        />
                    </div>
                </div>
            )}

            {updatedBy && (
                <div className="mb-2 row">
                    <label className="col-md-3 col-form-label">Editado Por</label>
                    <div className="col-md-9">
                        <input
                            type="text"
                            className="form-control"
                            readOnly={true}
                            value={updatedBy}
                        />
                    </div>
                </div>
            )}

            {updatedAt && (
                <div className="mb-2 row">
                    <label className="col-md-3 col-form-label">Fecha Actualización</label>
                    <div className="col-md-9">
                        <input
                            type="text"
                            className="form-control"
                            readOnly={true}
                            value={momentParseDate(updatedAt)}
                        />
                    </div>
                </div>
            )}
            {status && (
                <div className="mb-2 row">
                    <label className="col-md-3 col-form-label">Estado</label>
                    <div className="col-md-9">
                        <input
                            type="text"
                            className="form-control"
                            readOnly={true}
                            value={status}
                        />
                    </div>
                </div>
            )}
        </>
    );
};

export default DefaultCreatedModifiedUserForm;
