interface ItemsProps {
    permissions: string[];
    paths: string[];
    to?: string;
}

export const ITEMS = {

    batches: {
        permissions: ['view_batch'],
        paths: ['/batches'],
        to: '/batches'
    },
    batch_types: {
        permissions: ['view_batchtype'],
        paths: ['/batch-types'],
        to: '/batch-types'
    },
    brands: {
        permissions: ['view_brand'],
        paths: ['/brands'],
        to: '/brands'
    },
    carriers: {
        permissions: ['view_carrier'],
        paths: ['/carriers'],
        to: '/carriers'
    },
    clients: {
        permissions: ['view_client'],
        paths: ['/clients'],
        to: '/clients'
    },
    daily_carriers: {
        permissions: ['view_dailycarrier'],
        paths: ['/daily-carriers'],
        to: '/daily-carriers'
    },
    centers: {
        permissions: ['view_center'],
        paths: ['/centers'],
        to: '/centers'
    },
    clasifications: {
        permissions: ['view_clasifications'],
        paths: ['/clasifications'],
        to: '/clasifications'
    },
    accounts: {
        permissions: ['view_accounts'],
        paths: ['/accounts'],
        to: '/accounts'
    },
    currency: {
        permissions: ['view_currency'],
        paths: ['/currencies'],
        to: '/currencies'
    },
    unit: {
        permissions: ['view_unit'],
        paths: ['/units'],
        to: '/units'
    },
    foreing_exchange: {
        permissions: ['view_foreignexchange'],
        paths: ['/foreign-exchanges'],
        to: '/foreign-exchanges'
    },
    dashboard: {
        permissions: ['view_dashboard'],
        paths: ['/dashboard'],
        to: '/dashboard'
    },
    depots: {
        permissions: ['view_depot'],
        paths: ['/depots'],
        to: '/depots'
    },
    dispatch_programs: {
        permissions: ['view_dispatchprogram'],
        paths: ['/dispatch-programs'],
        to: '/dispatch-programs'
    },
    hoppers: {
        permissions: ['view_hopper'],
        paths: ['/hoppers'],
        to: '/hoppers'
    },
    incoterms: {
        permissions: ['view_incoterm'],
        paths: ['/incoterms'],
        to: '/incoterms'
    },
    item_categories: {
        permissions: ['view_itemcategory'],
        paths: ['/item-categories'],
        to: '/item-categories'
    },
    origin_areas: {
        permissions: ['view_originarea'],
        paths: ['/origin-areas'],
        to: '/origin-areas'
    },
    packing_forms: {
        permissions: ['view_packingform'],
        paths: ['/packing-forms'],
        to: '/packing-forms'
    },
    payment_status: {
        permissions: ['view_paymentstatus'],
        paths: ['/payment-statuses'],
        to: '/payment-statuses'
    },
    places: {
        permissions: ['view_place'],
        paths: ['/places'],
        to: '/places'
    },
    products: {
        permissions: ['view_product'],
        paths: ['/products'],
        to: '/products'
    },
    providers: {
        permissions: ['view_provider'],
        paths: ['/providers'],
        to: '/providers'
    },
    provider_types: {
        permissions: ['view_providertype'],
        paths: ['/provider-types'],
        to: '/provider-types'
    },
    purchase_order: {
        permissions: ['view_purchaseorder'],
        paths: ['/purchase-orders'],
        to: '/purchase-orders'
    },
    quotes: {
        permissions: ['view_quote'],
        paths: ['/quotes'],
        to: '/quotes'
    },
    route_segments: {
        permissions: ['view_routesegment'],
        paths: ['/route-segments'],
        to: '/route-segments'
    },
    sectors: {
        permissions: ['view_sector'],
        paths: ['/sectors'],
        to: '/sectors'
    },
    services: {
        permissions: ['view_service'],
        paths: ['/services'],
        to: '/services'
    },
    service_manifests: {
        permissions: ['view_servicemanifest'],
        paths: ['/service-manifests'],
        to: '/service-manifests'
    },
    society: {
        permissions: ['view_society'],
        paths: ['/societies'],
        to: '/societies'
    },
    society_signatures :{
        permissions: ['view_societysignature'],
        paths: ['/societies-signatures'],
        to: '/societies-signatures'
    },
    transportation_orders: {
        permissions: ['view_transportationorder'],
        paths: ['/transportation-orders'],
        to: '/transportation-orders'
    },
    taxes: {
        permissions: ['view_tax'],
        paths: ['/taxes'],
        to: '/taxes'
    },
    trucks: {
        permissions: ['view_truck'],
        paths: ['/trucks'],
        to: '/trucks'
    },
    users: {
        permissions: ['view_user'],
        paths: ['/users'],
        to: '/users'
    },
    checking_account: {
        permissions: ['view_checkingaccount'],
        paths: ['/checking-accounts'],
        to: '/checking-accounts'
    },
    internal_settlement: {
        permissions: ['view_internalsettlement_extra'],
        paths: ['/internal-settlements'],
        to: '/internal-settlements'
    },
    bank: {
        permissions: ['view_bank'],
        paths: ['/banks'],
        to: '/banks'
    },
    account_type: {
        permissions: ['view_accounttype'],
        paths: ['/bank-account-types'],
        to: '/bank-account-types'
    },
};

export const MENU_ITEMS = {
    no_module_single_items: {
        permissions: [...ITEMS.dashboard.permissions],
        paths: [...ITEMS.dashboard.paths]
    },

    //***** Sales  submenu
    sales_single_items: {
        permissions: [...ITEMS.quotes.permissions, ...ITEMS.transportation_orders.permissions],
        paths: [...ITEMS.quotes.paths, ...ITEMS.transportation_orders.paths]
    },

    //***** Logistics submenu
    logistics_single_items: {
        permissions: [...ITEMS.dispatch_programs.permissions, ...ITEMS.daily_carriers.permissions],
        paths: [...ITEMS.dispatch_programs.paths, ...ITEMS.daily_carriers.paths]
    },

    //***** Purchases submenu
    purchases_single_items: {
        permissions: [
            ...ITEMS.payment_status.permissions,
            ...ITEMS.purchase_order.permissions,
            ...ITEMS.service_manifests.permissions,
            ...ITEMS.providers.permissions
        ],
        paths: [
            ...ITEMS.payment_status.paths,
            ...ITEMS.purchase_order.paths,
            ...ITEMS.service_manifests.paths,
            ...ITEMS.providers.paths
        ]
    },
    // ********** checking_account submenu
    checking_account_single_items: {
        permissions: [
            ...ITEMS.checking_account.permissions,
            ...ITEMS.internal_settlement.permissions
        ],
        paths: [...ITEMS.checking_account.paths, ...ITEMS.internal_settlement.paths]
    },

    //***** Management submenu
    management_single_items: {
        permissions: [
            ...ITEMS.clients.permissions,
            ...ITEMS.users.permissions,
            ...ITEMS.places.permissions,
            ...ITEMS.provider_types.permissions,
            ...ITEMS.carriers.permissions,
            ...ITEMS.trucks.permissions,
            ...ITEMS.hoppers.permissions,
            ...ITEMS.route_segments.permissions,
            ...ITEMS.society_signatures.permissions,
            ...ITEMS.products.permissions,
            ...ITEMS.taxes.permissions,
            ...ITEMS.item_categories.permissions,
            ...ITEMS.services.permissions,
            ...ITEMS.incoterms.permissions,
            ...ITEMS.sectors.permissions,
            ...ITEMS.packing_forms.permissions,
            ...ITEMS.origin_areas.permissions,
            ...ITEMS.currency.permissions,
            ...ITEMS.foreing_exchange.permissions,
            ...ITEMS.unit.permissions,
            ...ITEMS.batches.permissions,
            ...ITEMS.batch_types.permissions,
            ...ITEMS.brands.permissions,
            ...ITEMS.depots.permissions,
            ...ITEMS.centers.permissions,
            ...ITEMS.bank.permissions,
            ...ITEMS.account_type.permissions,
        ],
        paths: [
            ...ITEMS.clients.paths,
            ...ITEMS.users.paths,
            ...ITEMS.places.paths,
            ...ITEMS.provider_types.paths,
            ...ITEMS.carriers.paths,
            ...ITEMS.trucks.paths,
            ...ITEMS.hoppers.paths,
            ...ITEMS.route_segments.paths,
            ...ITEMS.society_signatures.paths,
            ...ITEMS.products.paths,
            ...ITEMS.taxes.paths,
            ...ITEMS.item_categories.paths,
            ...ITEMS.services.paths,
            ...ITEMS.incoterms.paths,
            ...ITEMS.sectors.paths,
            ...ITEMS.packing_forms.paths,
            ...ITEMS.origin_areas.paths,
            ...ITEMS.batches.paths,
            ...ITEMS.batch_types.paths,
            ...ITEMS.brands.paths,
            ...ITEMS.depots.paths,
            ...ITEMS.centers.paths,
            ...ITEMS.currency.paths,
            ...ITEMS.foreing_exchange.paths,
            ...ITEMS.unit.paths,
            ...ITEMS.bank.paths,
            ...ITEMS.account_type.paths,
        ]
    }
};

// *********** ALL MODULES *************

const sales_module: ItemsProps[] = [MENU_ITEMS.sales_single_items];
const logistics_module: ItemsProps[] = [MENU_ITEMS.logistics_single_items];
const purchases_module: ItemsProps[] = [MENU_ITEMS.purchases_single_items];
const checking_account_module: ItemsProps[] = [MENU_ITEMS.checking_account_single_items];
const management_module: ItemsProps[] = [MENU_ITEMS.management_single_items];

export const PERMISSIONS_APP = {
    sales: sales_module,
    logistics: logistics_module,
    purchases: purchases_module,
    checking_account: checking_account_module,
    management: management_module
};

export const verify_permission = (
    modulePermissions: ItemsProps[],
    permissionUser: Array<string>
) => {
    for (const module of modulePermissions) {
        for (const userPermission of permissionUser) {
            if (module.permissions.includes(userPermission)) {
                return true;
            }
        }
    }
    return false;
};

export const verify_path = (modulePath: string[], pathname: string) => {
    if (modulePath.some((path) => pathname.includes(path))) {
        return true;
    }
};
